import React, {
  FC,
  FormEvent,
  MouseEvent,
  ReactElement,
  useRef,
  useState,
} from 'react';
import { useMutation, useSuspenseQuery } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';

import { ILLUSTRATION_SETTINGS } from 'config';
import { FullWidthGrid } from 'components/elements';
import { FallbackIllustration } from 'components/FallbackIllustration';
import { InteractiveButton } from 'components/InteractiveButton';
import { deleteFile, getFile, patchUser, postFile } from 'lib/api';
import debugDefault from 'lib/debug';
import { useHover } from 'lib/hooks';
import { File as APIFile } from 'lib/interfaces';
import { User as CurrentUser } from 'lib/utils';

import { Container, Veil } from './FileUpload.styles';

interface FileUploadProps {
  fileId?: number;
  onSuccessfulUpload?: (fileId: number) => void;
}

export const FileUpload: FC<FileUploadProps> = ({
  fileId,
  onSuccessfulUpload,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isHover, onEnter, onLeave] = useHover(false);

  const { loading: postFileLoading, mutate: sendPostFileRequest } = useMutation(
    postFile,
  );
  const { loading: saveAvatarLoading, mutate: saveAvatarRequest } = useMutation(
    patchUser,
  );
  const {
    loading: deleteAvatarLoading,
    mutate: deleteAvatarRequest,
  } = useMutation(deleteFile);

  const {
    payload: filePayload,
    error: fileError,
    errorObject: fileErrorObject,
  } = useSuspenseQuery<APIFile>({
    id: fileId,
    ...getFile,
  });

  function closeModal(): void {
    setIsOpen(false);
  }

  function openModal(e: MouseEvent): void {
    e.preventDefault();
    setIsOpen(true);
  }

  async function onFileUploadSubmit(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    await removeCurrentAvatarFile();
    const savedFile = await sendFile();
    if (savedFile) {
      saveAvatar(savedFile.id);
    }
  }

  async function removeCurrentAvatarFile() {
    if (!fileId) {
      return;
    }
    try {
      await deleteAvatarRequest(fileId);
    } catch (err) {
      debugDefault(err);
    }
  }

  async function sendFile(): Promise<APIFile> {
    if (
      !fileInputRef.current ||
      !fileInputRef.current.files ||
      fileInputRef.current.files.length < 1
    ) {
      enqueueSnackbar('Veuillez sélectionner un fichier', {
        variant: 'warning',
      });
      return;
    }
    const selectedFile = fileInputRef.current.files[0];
    const { error, errorObject, payload } = await sendPostFileRequest(
      selectedFile,
    );
    if (error) {
      debugDefault(errorObject);
      enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
    } else if (payload) {
      return payload;
    }
    return;
  }

  async function saveAvatar(avatarId: number) {
    const message =
      avatarId === 0
        ? 'Votre nouvelle photo de profil a bien été supprimée'
        : 'Votre nouvelle photo de profil a bien été enregistrée';
    const avatarRequestPayload = {
      avatar: avatarId,
      userId: CurrentUser.userId,
    };
    const { error, errorObject } = await saveAvatarRequest(
      avatarRequestPayload,
    );
    if (error) {
      debugDefault(errorObject);
      enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
      return;
    }
    enqueueSnackbar(message, {
      variant: 'success',
    });
    closeModal();
    const newFileId = avatarId ? avatarId : null;
    onSuccessfulUpload(newFileId);
  }

  async function removeAvatar(e: MouseEvent) {
    e.preventDefault();
    await removeCurrentAvatarFile();
    await saveAvatar(0);
  }

  let illustration: ReactElement = (
    <FallbackIllustration
      height={ILLUSTRATION_SETTINGS.SUMMARY}
      icon={<PersonIcon />}
    />
  );
  if (fileError) {
    debugDefault(fileErrorObject);
  } else if (filePayload && filePayload.data && filePayload.data.full_url) {
    illustration = (
      <FullWidthGrid justify="center" container>
        <img
          height={ILLUSTRATION_SETTINGS.SUMMARY}
          src={filePayload.data.full_url}
          alt={filePayload.title}
          title={filePayload.title}
        />
      </FullWidthGrid>
    );
  }
  return (
    <>
      <Container onClick={openModal} onMouseOver={onEnter} onMouseOut={onLeave}>
        {illustration}
        <Veil isHover={isHover}>
          <Typography variant="button" component="span">
            Modifiez votre photo
          </Typography>
        </Veil>
      </Container>
      <Dialog open={isOpen} onClose={closeModal} scroll="body">
        <form onSubmit={onFileUploadSubmit}>
          <DialogTitle>Modifiez votre photo de profil</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sélectionnez une nouvelle image
              <Typography variant="subtitle2">
                Fichiers acceptés : JPG, JPEG et PNG
              </Typography>
            </DialogContentText>
            <p>
              <input accept=".png,.jpg,.jpeg" ref={fileInputRef} type="file" />
            </p>
          </DialogContent>
          <DialogActions>
            {!(postFileLoading || saveAvatarLoading || deleteAvatarLoading) && (
              <>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={removeAvatar}
                  disabled={!fileId}
                >
                  Supprimer ma photo de profil
                </Button>
                <Typography variant="subtitle1" color="secondary">
                  &nbsp;OU&nbsp;
                </Typography>
              </>
            )}
            <InteractiveButton
              label="Modifier ma photo de profil"
              loading={
                postFileLoading || saveAvatarLoading || deleteAvatarLoading
              }
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
