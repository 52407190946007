import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ContactInformationList } from 'components/ContactInformationList';
import { ContainerPage } from 'components/Page';
import { Section, ShadowGrid } from 'components/elements';
import { MenuEntries } from 'lib/enums';
import { useTitle } from 'lib/hooks';

import { ContactForm } from './ContactForm';

export const Contact: FC<{}> = () => {
  const { t } = useTranslation('contact');
  const title = useTitle(MenuEntries.Contact);

  return (
    <>
      <Helmet>{title}</Helmet>
      <ContainerPage bottomDivider forceRole="contact" />
      <ShadowGrid container>
        <Grid item xs={12} md={4}>
          <Section container>
            <Grid item xs={12}>
              <Typography variant="h5">{t('information_title')}</Typography>
              <ContactInformationList />
            </Grid>
          </Section>
        </Grid>
        <Grid item xs={12} md={8}>
          <Section container>
            <Grid item xs={12}>
              <Typography variant="h5">{t('message_title')}</Typography>
              <ContactForm />
            </Grid>
          </Section>
        </Grid>
      </ShadowGrid>
    </>
  );
};
