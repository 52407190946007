import { MouseEvent, useState } from 'react';

export function useHover(
  initialHover: boolean = false,
): [boolean, (e: MouseEvent) => void, (e: MouseEvent) => void] {
  const [isHover, setIsHover] = useState<boolean>(initialHover);

  function onEnter(e: MouseEvent): void {
    setIsHover(true);
  }

  function onLeave(e: MouseEvent): void {
    setIsHover(false);
  }
  return [isHover, onEnter, onLeave];
}
