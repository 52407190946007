import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-fetching-library';

import { ErrorMessage } from 'components/ErrorMessage';
import { ContainerPage } from 'components/Page';
import { PaddedGrid } from 'components/elements';
import { PostCard } from 'components/PostCard';
import { getPostList, getPostTranslationList } from 'lib/api';
import { debugNews } from 'lib/debug';
import { MenuEntries } from 'lib/enums';
import { useTitle, useUserList } from 'lib/hooks';
import { Post, TranslatedPost, User } from 'lib/interfaces';

export const News: FC<{}> = () => {
  const { t, i18n } = useTranslation('errors');
  const title = useTitle(MenuEntries.News);

  const {
    error: postListError,
    errorObject: postListErrorObject,
    payload: postList,
  } = useQuery<Post[]>({
    params: {
      sort: 'id',
      fields: 'id',
    },
    ...getPostList,
  });
  const {
    error: userListError,
    errorObject: userListErrorObject,
    userList,
  } = useUserList();

  const {
    error: translatedPostListError,
    errorObject: translatedPostListErrorObject,
    payload: translatedPostList,
  } = useQuery<TranslatedPost[]>({
    params: {
      'filter[language_code]': i18n.language,
      sort: 'article',
      fields: 'article,titre,contenu,url,created_by,created_on',
    },
    ...getPostTranslationList,
  });

  let content: ReactElement = null;
  if (postListError || translatedPostListError || userListError) {
    debugNews(postListErrorObject);
    debugNews(translatedPostListErrorObject);
    debugNews(userListErrorObject);
    content = <ErrorMessage message={t('unknown-error')} />;
  } else if (postList && translatedPostList && userList) {
    const fullPostList = mergePostAndTranslationList(
      postList,
      translatedPostList,
      userList,
    );
    debugNews(fullPostList);
    if (fullPostList.length > 0) {
      content = (
        <PaddedGrid container justify="flex-start">
          {fullPostList.map((post, index) => (
            <PaddedGrid isHalf item xs={12} md={6} key={index}>
              <PostCard
                author={post.created_by}
                content={post.contenu}
                createdOn={post.created_on}
                illustration={post.illustration}
                title={post.titre}
                slug={post.url}
              />
            </PaddedGrid>
          ))}
        </PaddedGrid>
      );
    } else {
      content = <ErrorMessage message={t('no-news')} />;
    }
  }
  return (
    <>
      <Helmet>{title}</Helmet>
      <ContainerPage bottomDivider forceRole="news" />
      {content}
    </>
  );
};

interface FullPost {
  created_by: User;
  created_on: string;
  illustration?: number;
  titre?: string;
  contenu?: string;
  url: string;
}

function mergePostAndTranslationList(
  postList: Post[],
  translatedPostList: TranslatedPost[],
  userList: User[],
): FullPost[] {
  const out: FullPost[] = [];
  if (postList.length >= 1 && translatedPostList.length >= 1) {
    let nextIndex = 0;
    let post: Post;
    for (post of postList) {
      let nextTranslatedPost = translatedPostList[nextIndex];
      while (
        nextTranslatedPost &&
        parseInt(nextTranslatedPost.article, 10) !== post.id
      ) {
        nextIndex++;
        nextTranslatedPost = translatedPostList[nextIndex];
      }
      const author = nextTranslatedPost
        ? userList.find(user => user.id === nextTranslatedPost.created_by)
        : null;
      debugNews(userList, author);
      if (nextTranslatedPost) {
        out.push({
          ...post,
          ...nextTranslatedPost,
          created_by: author,
        });
        nextIndex++;
      } else {
        break;
      }
    }
  }
  return out;
}
