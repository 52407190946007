import React, { FC } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import Grid from '@material-ui/core/Grid';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { ColorVariantEnum } from 'lib/enums';

import landingImage from './books.jpg';

export const Container = styled.div<{ isXs?: boolean }>`
  padding: ${props => props.theme.spacing.s16}
    ${props => (props.isXs ? props.theme.spacing.s2 : props.theme.spacing.s16)};
`;

export const Cover = styled(Grid)`
  background: ${props =>
    transparentize(0.3, props.theme.colors.white(ColorVariantEnum.darker))};
  height: calc(
    100vh - ${props => props.theme.spacing.s12} -
      ${props => props.theme.spacing.s5}
  );
  overflow: hidden !important;
  width: 100vw;
`;

export const FullImage = styled.div`
  background: url(${landingImage}) no-repeat top center;
  background-attachment: fixed;
  background-size: cover;
  height: calc(
    100vh - ${props => props.theme.spacing.s12} -
      ${props => props.theme.spacing.s5}
  );
  opacity: 0.8;
  position: absolute;
  width: 100%;
  z-index: -1;
`;

export const Logo = styled.img`
  height: ${props => props.theme.spacing.s32};
`;

interface WebsiteTitle extends TypographyProps {
  isSubtitle?: boolean;
}

export const WebsiteTitle = styled<FC<WebsiteTitle>>(
  ({ isSubtitle, ...rest }) => <Typography {...rest} />,
)`
  font-size: ${props =>
    props.isSubtitle
      ? props.theme.font.size.larger
      : props.theme.font.size.largest};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.spacing.s1};
  padding: 0 ${props => props.theme.spacing.s4};
  margin-top: ${props => props.theme.spacing.s4};
  text-align: center;
  text-transform: uppercase;
  word-spacing: ${props => props.theme.spacing.s2};
`;

export const SpacedGrid = styled<FC<{ isXs?: boolean }>>(
  ({ isXs, ...rest }) => <Grid item xs={12} sm={6} md={4} {...rest} />,
)`
  padding-top: ${props =>
    props.isXs ? props.theme.spacing.s6 : props.theme.spacing.s8};
  padding-right: ${props => (props.isXs ? '0' : props.theme.spacing.s8)};
`;

export const TitleBox = styled(Grid)`
  color: ${props => props.theme.colors.primary(ColorVariantEnum.dark)};
  z-index: 10;
`;
