import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { ColorVariantEnum } from 'lib/enums';

interface HoverProps {
  isHover: boolean;
}

export const CardLink = styled(Link)`
  align-items: flex-start;
  display: flex;
  height: ${props => props.theme.spacing.s24};
  padding: ${props => props.theme.spacing.s4};
  text-decoration: none;
  transition: all 0.3s ease;
`;

export const CardNativeLink = styled.a`
  align-items: flex-start;
  display: flex;
  height: ${props => props.theme.spacing.s24};
  padding: ${props => props.theme.spacing.s4};
  text-decoration: none;
  transition: all 0.3s ease;
`;

export const Container = styled.div<HoverProps>`
  a {
    color: ${props =>
      props.isHover ? props.theme.colors.primary() : 'inherit'}!important;

    h4 {
      color: ${props =>
        props.isHover
          ? props.theme.colors.primary(ColorVariantEnum.dark)
          : 'inherit'}!important;
    }

    svg {
      margin-top: ${props => props.theme.spacing.s1};
    }
  }
`;

export const Content = styled(Typography)`
  margin-top: ${props => props.theme.spacing.s2};
  font-size: ${props => props.theme.font.size.medium};
  font-weight: ${props => props.theme.font.weight.thin};
`;

export const IconContainer = styled.div`
  margin-right: ${props => props.theme.spacing.s4};
`;

export const Title = styled(Typography)`
  font-size: ${props => props.theme.font.size.mediumLarge};
  font-weight: ${props => props.theme.font.weight.regular};
`;
