import React, { FC, FormEvent, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import {
  FullWidthGrid,
  FullWidthTextField,
  XsContainer,
} from 'components/elements';
import { InteractiveButton } from 'components/InteractiveButton';
import { postPasswordReset } from 'lib/api';
import { debugLogin } from 'lib/debug';

interface ResetPasswordFormProps {
  token: string;
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ token }) => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [newRetypePassword, setNewRetypePassword] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  const { loading, mutate: sendNewPassword } = useMutation(postPasswordReset);

  async function onResetPasswordSubmit(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (newRetypePassword !== newPassword) {
      enqueueSnackbar('Les deux champs "mot de passe" ne correspondent pas.', {
        variant: 'warning',
      });
      return;
    }
    if (!newPassword) {
      return;
    }
    const { payload, error, errorObject } = await sendNewPassword({
      password: newPassword,
      token,
    });
    if (error) {
      debugLogin(errorObject);
      enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
    } else if (payload) {
      enqueueSnackbar('Votre mot de passe a bien été réinitialisé', {
        variant: 'success',
      });
    }
  }

  return (
    <form onSubmit={onResetPasswordSubmit}>
      <XsContainer
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FullWidthGrid item>
          <FullWidthTextField
            autoComplete="new-password"
            required
            placeholder="Nouveau mot de passe"
            variant="outlined"
            value={newPassword}
            type="password"
            onChange={e => setNewPassword(e.currentTarget.value)}
          />
        </FullWidthGrid>
        <FullWidthGrid item>
          <FullWidthTextField
            autoComplete="new-password"
            required
            placeholder="Retaper nouveau mot de passe"
            variant="outlined"
            value={newRetypePassword}
            type="password"
            onChange={e => setNewRetypePassword(e.currentTarget.value)}
            error={newRetypePassword !== newPassword}
            helperText={
              newRetypePassword !== newPassword
                ? 'Les deux champs ne corespondent pas'
                : ''
            }
          />
        </FullWidthGrid>
        <FullWidthGrid
          item
          container
          justify="center"
          direction="column"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <InteractiveButton
              loading={loading}
              label="Envoyer"
              variant="contained"
              type="submit"
              disabled={!newPassword}
            />
          </Grid>
          <Grid item>
            <Link to="login">Se connecter</Link>
          </Grid>
        </FullWidthGrid>
      </XsContainer>
    </form>
  );
};
