import { Action } from 'react-fetching-library';

export function authenticate({
  email,
  password,
}: {
  email: string;
  password: string;
}): Action {
  return {
    method: 'POST',
    endpoint: 'auth/authenticate',
    body: {
      email,
      password,
      mode: 'cookie',
    },
  };
}
