import React, { FC, ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';

import { Colored } from 'components/Colored';

import { Container, Icon } from './InformationEntry.styles';

interface InformationEntryProps {
  body: string | ReactElement;
  icon: ReactElement;
}

export const InformationEntry: FC<InformationEntryProps> = ({ body, icon }) => {
  return (
    <Container>
      <Icon>
        <Typography variant="subtitle2" component="dt">
          <Colored>{icon}</Colored>
        </Typography>
      </Icon>
      <div>
        <Typography variant="body1" component="dd">
          {body}
        </Typography>
      </div>
    </Container>
  );
};
