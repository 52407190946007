import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LazyLoading from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // load translation from /public/locales
  .use(LazyLoading)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['fr', 'en', 'de', 'it', 'rm'],
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
