import React, { FC } from 'react';
import moment from 'moment';
import TodayIcon from '@material-ui/icons/Today';
import PersonIcon from '@material-ui/icons/Person';

import { User } from 'lib/interfaces';
import { Container } from './PostInformation.styles';

interface PostInformationProps {
  author?: User;
  centered?: boolean;
  createdOn: string;
  isHover?: boolean;
}

export const PostInformation: FC<PostInformationProps> = ({
  author,
  centered,
  createdOn,
  isHover,
}) => {
  const authorName = author ? `${author.first_name} ${author.last_name}` : '';

  return (
    <Container variant="subtitle1" centered={centered} isHover={isHover}>
      <TodayIcon fontSize="small" />
      {moment(createdOn).format('LL')}
      {authorName && (
        <>
          &nbsp;&#8226;&nbsp;
          <PersonIcon fontSize="small" />
          {authorName}
        </>
      )}
    </Container>
  );
};
