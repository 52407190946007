import React, { FC, ReactElement } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import Grid from '@material-ui/core/Grid';

import { Section, SubTitle } from 'components/elements';
import { ErrorMessage } from 'components/ErrorMessage';
import { ProfileForm } from 'components/ProfileForm';
import { getCurrentUser } from 'lib/api';
import { debugProfile } from 'lib/debug';
import { User } from 'lib/interfaces';

export const Profile: FC<{}> = () => {
  const {
    payload: currentUserPayload,
    error: currentUserError,
    errorObject: currentUserErrorObject,
  } = useSuspenseQuery<User>({
    params: {
      fields:
        'first_name,last_name,avatar,email_public,adresse,telephone,description,lien,role',
    },
    ...getCurrentUser,
  });

  let content: ReactElement;

  if (currentUserError) {
    debugProfile(currentUserErrorObject);
    content = <ErrorMessage message="Une erreur est survenue" />;
  } else if (currentUserPayload) {
    const {
      first_name: firstName,
      last_name: lastName,
      email_public: emailPublic,
      avatar,
      adresse,
      telephone,
      description,
      lien,
      role,
    } = currentUserPayload;
    content = (
      <ProfileForm
        firstName={firstName}
        lastName={lastName}
        isEmailPublic={emailPublic}
        avatar={avatar}
        address={adresse}
        phone={telephone}
        description={description}
        link={lien}
        role={role}
      />
    );
  }
  return (
    <Section container alignItems="center" justify="center">
      <Grid item xs={12}>
        <SubTitle>Votre profil</SubTitle>
      </Grid>
      <Grid container item xs={12} md={8} xl={6}>
        {content}
      </Grid>
    </Section>
  );
};
