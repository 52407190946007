import styled from 'styled-components';

export const Container = styled.div<{ isEmbedded: boolean }>`
  margin-left: -${props => (props.isEmbedded ? props.theme.spacing.s16 : 0)};
  min-height: 100px;
  height: 35vh;
  width: 100vw;
`;

export const FullImage = styled.div<{ imagePath: string }>`
  background: url(${props => props.imagePath}) no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
`;
