import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { MenuEntries } from 'lib/enums';

import { Header } from '../Header';

export const TranslatedHeader: React.FC<{}> = () => {
  const { i18n, t } = useTranslation('menu');

  const linkList = Object.values(MenuEntries).map(value => {
    switch (value) {
      case MenuEntries.Home:
        return (
          <NavLink exact title={t(value)} to="">
            {t(value)}
          </NavLink>
        );
      default:
        return (
          <NavLink exact title={t(value)} to={`/${i18n.language}/${value}`}>
            {t(value)}
          </NavLink>
        );
    }
  });

  return <Header linkList={linkList} />;
};
