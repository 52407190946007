import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';

import { FullHeightGrid } from 'components/elements';

import { Footer } from './Footer';
import { Header } from './Header';
import { PrivateHeader } from './PrivateHeader';
import { TranslatedFooter } from './TranslatedFooter';
import { TranslatedHeader } from './TranslatedHeader';

interface LayoutProps {
  children?: ReactElement | ReactElement[];
  isStatic?: boolean;
  isPrivate?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  isStatic = false,
  isPrivate = false,
}) => {
  let footer: ReactElement;
  let header: ReactElement;

  if (isStatic) {
    footer = <Footer />;
    header = <Header isStatic />;
  } else if (isPrivate) {
    footer = <TranslatedFooter />;
    header = <PrivateHeader />;
  } else {
    footer = <TranslatedFooter />;
    header = <TranslatedHeader />;
  }
  return (
    <FullHeightGrid
      container
      justify="space-between"
      direction="column"
      wrap="nowrap"
    >
      <Grid item>
        {header}
        {children}
      </Grid>
      <Grid item>{footer}</Grid>
    </FullHeightGrid>
  );
};
