import React, { CSSProperties, FC, ReactElement, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ColorVariantEnum } from 'lib/enums';

export interface ColoredProps {
  children: ReactElement | ReactElement[];
  className?: string;
  color?: 'primary' | 'secondary' | 'black' | 'white';
  isBackground?: boolean;
  variant?: ColorVariantEnum;
}

export const Colored: FC<ColoredProps> = ({
  children,
  className = '',
  color = 'primary',
  isBackground,
  variant = ColorVariantEnum.default,
}) => {
  const { colors } = useContext(ThemeContext);
  function getStyle(): CSSProperties {
    const finalColor = colors[color](variant);
    if (isBackground) {
      return { background: finalColor };
    }
    return { color: finalColor };
  }
  return (
    <span className={className} style={getStyle()}>
      {children}
    </span>
  );
};
