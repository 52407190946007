import React, {
  AnchorHTMLAttributes,
  FC,
  ReactElement,
  createElement,
  useState,
} from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Card from '@material-ui/core/Card';

import {
  CardLink,
  CardNativeLink,
  Container,
  Content,
  IconContainer,
  Title,
} from './QuickAccess.styles';

interface QuickAccessProps {
  content: string;
  icon: (props: SvgIconProps) => ReactElement;
  isNativeLink?: boolean;
  link: string;
  openInNewTab?: boolean;
  title: string;
}

export const QuickAccess: FC<QuickAccessProps> = ({
  content,
  icon,
  isNativeLink = false,
  link,
  openInNewTab = false,
  title,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  function onCardEnter(): void {
    setIsHover(true);
  }

  function onCardLeave(): void {
    setIsHover(false);
  }

  const Icon = createElement(icon, { fontSize: 'large' });

  const cardContent = (
    <>
      <IconContainer>{Icon}</IconContainer>
      <div>
        <Title variant="h4">{title}</Title>
        <Content variant="body1">{content}</Content>
      </div>
    </>
  );

  const extraProps: AnchorHTMLAttributes<HTMLAnchorElement> = {};
  if (isNativeLink && openInNewTab) {
    extraProps.target = '_blank';
    extraProps.rel = 'noopener noreferrer';
  }

  return (
    <Card
      elevation={isHover ? 12 : 0}
      onMouseOver={onCardEnter}
      onMouseOut={onCardLeave}
    >
      <Container isHover={isHover}>
        {isNativeLink ? (
          <CardNativeLink href={link} {...extraProps}>
            {cardContent}
          </CardNativeLink>
        ) : (
          <CardLink to={`/${link}`}>{cardContent}</CardLink>
        )}
      </Container>
    </Card>
  );
};
