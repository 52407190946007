import { LOCALSTORAGE_FIELDS } from 'config';
import { LoginPayload } from 'lib/interfaces';

export class User {
  static authenticate(loginPayload: LoginPayload): void {
    localStorage.setItem(
      LOCALSTORAGE_FIELDS.AUTH_METHOD,
      loginPayload.authMethod,
    );
    localStorage.setItem(
      LOCALSTORAGE_FIELDS.USER,
      String(loginPayload.user.id),
    );
    localStorage.setItem(
      LOCALSTORAGE_FIELDS.ROLE,
      String(loginPayload.user.role),
    );
    return;
  }

  static clearSession(): void {
    localStorage.removeItem(LOCALSTORAGE_FIELDS.AUTH_METHOD);
    localStorage.removeItem(LOCALSTORAGE_FIELDS.USER);
    localStorage.removeItem(LOCALSTORAGE_FIELDS.ROLE);
    return;
  }

  static get isAuthenticated(): boolean {
    return !!localStorage.getItem(LOCALSTORAGE_FIELDS.AUTH_METHOD);
  }

  static get userId(): number {
    return parseInt(localStorage.getItem(LOCALSTORAGE_FIELDS.USER), 10);
  }

  static get userRole(): number {
    return parseInt(localStorage.getItem(LOCALSTORAGE_FIELDS.ROLE), 10);
  }
}
