import styled from 'styled-components';
import { transparentize } from 'polished';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import { ColorVariantEnum } from 'lib/enums';

export const LanguageItem = styled(MenuItem)`
  font-weight: ${props => props.theme.font.weight.thin};
`;

export const LanguageList = styled(Paper)`
  background: ${props =>
    transparentize(0.1, props.theme.colors.black(ColorVariantEnum.dark))};
  backdrop-filter: blur(4px);
  color: ${props => props.theme.colors.white(ColorVariantEnum.darker)};
`;

export const LanguageSwitch = styled.div`
  padding-left: ${props => props.theme.spacing.s6};

  button {
    color: ${props => props.theme.colors.white(ColorVariantEnum.dark)};
    font-weight: ${props => props.theme.font.weight.thin};
  }
`;
