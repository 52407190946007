import { createGlobalStyle } from 'styled-components';
import { ColorVariantEnum } from 'lib/enums';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
  }

  html, body, #root {
    height: 100%;
  }

  .truncate-overflow {
    position: relative;
    max-height: 2.30rem;
    overflow: hidden;
    padding-right: 1rem;
  }
  .truncate-overflow::before {
    position: absolute;
    content: "...";
    bottom: 0;
    right: 0;
  }
  .truncate-overflow::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
  }

  .float-left {
    margin-right: ${props => props.theme.spacing.s4};
  }
  .float-right {
    margin-left: ${props => props.theme.spacing.s4};
  }
  a, a:visited {
    color: ${props => props.theme.colors.secondary(ColorVariantEnum.dark)};
    transition: 0.2s ease all;
    text-decoration: none;

    &:hover,&:focus {
      color: ${props => props.theme.colors.primary()};
    }
  }
`;
