import React, { FC, ReactElement, useState } from 'react';
import { useQuery } from 'react-fetching-library';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import TranslateIcon from '@material-ui/icons/Translate';
import styled from 'styled-components';

import { DynamicCard } from 'components/elements';
import { FallbackIllustration } from 'components/FallbackIllustration';
import { ILLUSTRATION_SETTINGS } from 'config';
import { getFile } from 'lib/api';
import { debugSearch } from 'lib/debug';
import { File, Translator, TranslatorHabilitation } from 'lib/interfaces';
import { useHover } from 'lib/hooks';
import { nl2br } from 'lib/utils';

import { InformationEntry } from '../InformationEntry';
import {
  ChevronCell,
  FullCardHeader,
  FullCardHeaderSummary,
  HabilitationBlock,
  HabilitationEntry,
  HabilitationTable,
  ModalDivider,
  ResponsiveCardContent,
  SummaryCardBody,
  SummaryCardContent,
  SummaryHabilitationWrap,
  SummaryMoreHorizIcon,
  SummaryName,
} from './TranslatorCard.styles';

interface TranslatorCardProps extends Translator {
  languageMap: Map<string, string>;
}

export const TranslatorCard: FC<TranslatorCardProps> = ({
  adresse,
  avatar: avatarId,
  description,
  email,
  email_public: emailPublic,
  first_name: firstName,
  habilitations,
  languageMap,
  lien,
  last_name: lastName,
  telephone,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHover, onEnter, onLeave] = useHover(false);

  const {
    payload: avatar,
    error: avatarError,
    errorObject: avatarErrorObject,
  } = useQuery<File>({
    id: avatarId,
    ...getFile,
  });

  function closeModal(): void {
    setIsOpen(false);
  }

  function openModal(): void {
    setIsOpen(true);
  }

  function formatLink(link: string): string {
    if (link.startsWith('http')) {
      return link;
    }
    return `https://${link}`;
  }

  const FullDescription = (
    <>
      {description && (
        <InformationEntry
          body={nl2br(description)}
          icon={<InfoIcon titleAccess="about" />}
        />
      )}
      {lien && (
        <>
          <br />
          <InformationEntry
            body={
              <a
                href={formatLink(lien)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lien}
              </a>
            }
            icon={<LinkIcon titleAccess="link" />}
          />
        </>
      )}
      {(description || lien) && <ModalDivider variant="middle" />}
      {email && emailPublic && (
        <InformationEntry
          body={<a href={`mailto:${email}`}>{email}</a>}
          icon={<MailIcon titleAccess="email" />}
        />
      )}
      {telephone && (
        <InformationEntry
          body={<a href={`tel:${telephone}`}>{telephone}</a>}
          icon={<PhoneIcon titleAccess="phone" />}
        />
      )}
      {adresse && (
        <InformationEntry
          body={
            <a
              href={`https://www.google.com/maps/search/${adresse}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {nl2br(adresse)}
            </a>
          }
          icon={<HomeIcon titleAccess="address" />}
        />
      )}
    </>
  );

  function renderCard(type: 'summary' | 'full'): ReactElement {
    const illustrationHeight =
      type === 'summary'
        ? ILLUSTRATION_SETTINGS.SUMMARY
        : ILLUSTRATION_SETTINGS.FULL;

    let Illustration = (
      <CardMedia component="div" title={`${firstName} ${lastName}`}>
        <FallbackIllustration
          height={illustrationHeight}
          icon={<PersonIcon />}
        />
      </CardMedia>
    );
    if (avatarError) {
      debugSearch('avatar error', avatarErrorObject);
    }
    if (avatarId && avatar) {
      Illustration = (
        <CardMedia
          alt={`${firstName} ${lastName}`}
          component="img"
          height={illustrationHeight}
          image={avatar.data.full_url}
          title={`${firstName} ${lastName}`}
        />
      );
    }

    const HabilitationList = (
      <InformationEntry
        body={generateHabilitationTable(habilitations, type, languageMap)}
        icon={<TranslateIcon titleAccess="habilitations" />}
      />
    );
    if (type === 'summary') {
      return (
        <div>
          {Illustration}
          <SummaryCardContent>
            <SummaryName>
              <Typography variant="h5">
                {firstName} {lastName}
              </Typography>
            </SummaryName>
            <SummaryCardBody>{HabilitationList}</SummaryCardBody>
          </SummaryCardContent>
        </div>
      );
    }
    return (
      <div>
        <Hidden smDown>
          <FullCardHeader container>
            <Grid item xs={6}>
              <CardContent>
                <FullCardHeaderSummary
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <InformationEntry
                      body={<Typography variant="h5">{firstName}</Typography>}
                      icon={
                        <PersonIcon titleAccess="name" visibility="hidden" />
                      }
                    />
                    <InformationEntry
                      body={<Typography variant="h4">{lastName}</Typography>}
                      icon={
                        <PersonIcon titleAccess="name" visibility="hidden" />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <HabilitationBlock>{HabilitationList}</HabilitationBlock>
                  </Grid>
                </FullCardHeaderSummary>
              </CardContent>
            </Grid>
            <Grid item xs={6}>
              {Illustration}
            </Grid>
          </FullCardHeader>
        </Hidden>
        <Hidden mdUp>
          {Illustration}
          <ResponsiveCardContent>
            <Grid container item xs={12} direction="column">
              <Grid item>
                <InformationEntry
                  body={
                    <>
                      <Typography variant="h5" component="h5">
                        {firstName}&nbsp;
                        <Typography variant="h4" component="span">
                          {lastName}
                        </Typography>
                      </Typography>
                    </>
                  }
                  icon={<PersonIcon titleAccess="name" visibility="hidden" />}
                />
              </Grid>
              <Grid item>
                <HabilitationBlock>{HabilitationList}</HabilitationBlock>
              </Grid>
            </Grid>
          </ResponsiveCardContent>
        </Hidden>
        <CollapsibleCardContent
          isEmpty={
            !description && !lien && !emailPublic && !telephone && !adresse
          }
        >
          <Grid container justify="center" alignItems="flex-start">
            <Grid item xs={12}>
              {FullDescription}
            </Grid>
          </Grid>
        </CollapsibleCardContent>
      </div>
    );
  }
  return (
    <>
      <Fade in timeout={200}>
        <DynamicCard
          elevation={isHover ? 12 : 2}
          onClick={openModal}
          onMouseOver={onEnter}
          onMouseOut={onLeave}
        >
          {renderCard('summary')}
        </DynamicCard>
      </Fade>
      <Dialog open={isOpen} onClose={closeModal} scroll="body">
        <Card>{renderCard('full')}</Card>
      </Dialog>
    </>
  );
};

function generateHabilitationTable(
  habilitations: TranslatorHabilitation[],
  type: 'summary' | 'full',
  languageMap: Map<string, string>,
) {
  const renderedHabilitations =
    type === 'summary' && habilitations.length > 4
      ? habilitations.slice(0, 4)
      : habilitations;
  const table = (
    <HabilitationTable>
      <tbody>
        {renderedHabilitations.map((habilitation, index) => (
          <tr key={index}>
            <td>
              <HabilitationEntry variant="subtitle2">
                {languageMap[habilitation.from]}
              </HabilitationEntry>
            </td>
            <ChevronCell>
              <ChevronRightIcon fontSize="small" />
            </ChevronCell>
            <td>
              <HabilitationEntry variant="subtitle2">
                {languageMap[habilitation.to]}
              </HabilitationEntry>
            </td>
          </tr>
        ))}
      </tbody>
    </HabilitationTable>
  );
  if (type === 'summary' && habilitations.length > 4) {
    return (
      <SummaryHabilitationWrap>
        {table}
        {habilitations.length > 4 && <SummaryMoreHorizIcon fontSize="small" />}
      </SummaryHabilitationWrap>
    );
  }
  return table;
}

const CollapsibleCardContent = styled(({ isEmpty, ...rest }) => (
  <CardContent {...rest} />
))(({ isEmpty }: { isEmpty: boolean }) => ({
  padding: isEmpty ? '0 !important' : '',
}));
