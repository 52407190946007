import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { Block, Section } from 'components/elements';
import { Layout } from 'components/Layout';
import {
  ResetPasswordForm,
  ResetPasswordRequestForm,
} from 'components/ResetPasswordForm';
import { queryParametersParser } from 'lib/utils';

export const ResetPassword: FC<{}> = () => {
  const { search } = useLocation();
  const token = getToken(search);

  return (
    <Layout>
      <Section
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Block item>
          <Typography variant="h6">Réinitialisez votre mot de passe</Typography>
        </Block>
        <Block item>
          {token ? (
            <ResetPasswordForm token={token} />
          ) : (
            <ResetPasswordRequestForm />
          )}
        </Block>
      </Section>
    </Layout>
  );
};

function getToken(search: string): string {
  const qp = queryParametersParser(search);
  if (qp && qp.token) {
    return qp.token;
  }
  return null;
}
