import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { User } from 'lib/utils';

export const PrivateRoute: FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        User.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
