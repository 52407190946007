import Debug from 'debug';

const debugApp = Debug('astj:app');
const debugContact = Debug('astj:contact');
const debugDefault = Debug('astj:default');
const debugHelpers = Debug('astj:helpers');
const debugLang = Debug('astj:lang');
const debugLogin = Debug('astj:login');
const debugNetwork = Debug('astj:network');
const debugPage = Debug('astj:page');
const debugProfile = Debug('astj:profile');
const debugNews = Debug('astj:news');
const debugRoute = Debug('astj:route');
const debugSearch = Debug('astj:search');
const debugSettings = Debug('astj:settings');

export {
  debugApp,
  debugContact,
  debugDefault,
  debugHelpers,
  debugLang,
  debugLogin,
  debugNetwork,
  debugPage,
  debugProfile,
  debugNews,
  debugRoute,
  debugSearch,
  debugSettings,
};

export default debugDefault;
