import React, { FC } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { Colored } from 'components/Colored';

export const FormButton = styled(Button)`
  margin: ${props => props.theme.spacing.s4} 0;
`;

export const LabelIcon = styled(Colored)`
  margin-right: ${props => props.theme.spacing.s2};
`;

export const PaperField = styled<FC<{ notCentered?: boolean }>>(
  ({ notCentered, ...rest }) => <Paper {...rest} square />,
)`
  align-items: ${props => (props.notCentered ? 'flex-start' : 'center')};
  display: flex;
  padding: ${props => props.theme.spacing.s2};
  margin-top: ${props => props.theme.spacing.s4};
  width: calc(100% - 2 * ${props => props.theme.spacing.s2});

  & > div {
    flex-grow: 1;
  }
`;
