import React, { FC, Suspense, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Layout } from 'components/Layout';
import { Quill } from 'components/Quill';
import { ScrollToTop } from 'components/ScrollToTop';
import { PostTranslationContext } from 'lib/contexts';
import { LocalizedRoutes } from 'pages/LocalizedRoutes';
import { Login } from 'pages/Login';
import { Logout } from 'pages/Logout';
import { ResetPassword } from 'pages/ResetPassword';
import { PrivateRoutes } from 'pages/PrivateRoutes';
import { AvailableLanguages } from 'lib/enums';

interface AppState {
  postPathMap: Map<AvailableLanguages, string>;
  postId: number;
}

export const App: FC<{}> = () => {
  const [{ postPathMap, postId }, setStatePostPathMap] = useState<AppState>({
    postPathMap: null,
    postId: null,
  });
  function setPostPathMap(
    newPostPathMap: Map<AvailableLanguages, string>,
    newPostId: number,
  ) {
    setStatePostPathMap({
      postPathMap: newPostPathMap,
      postId: newPostId,
    });
  }

  return (
    <PostTranslationContext.Provider
      value={{ postId, postPathMap, setPostPathMap }}
    >
      <Suspense fallback={<Layout isStatic />}>
        <BrowserRouter>
          <ScrollToTop />
          <Quill />
          <Switch>
            <Route path="/members" component={PrivateRoutes} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route path="/:lang?" component={LocalizedRoutes} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </PostTranslationContext.Provider>
  );
};
