export enum FooterEntries1 {
  Home = 'home',
  About = 'about',
  Pricing = 'pricing',
  Profession = 'the-profession',
  Contact = 'contact',
  Search = 'search',
  Rss = 'rss',
}

export enum FooterEntries2 {
  Members = 'members',
  Admin = 'admin',
}
