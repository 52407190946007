import React, { FC } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import MUIDivider from '@material-ui/core/Divider';

import { Container } from './Divider.styles';

export const Divider: FC<{}> = () => {
  const theme = useTheme();
  return (
    <Container isSm={useMediaQuery(theme.breakpoints.down('sm'))}>
      <MUIDivider variant="middle" />
    </Container>
  );
};
