import React, { FC, MouseEvent, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { LanguageMenu } from 'components/LanguageMenu';
import { Banner, BannerContainer, Logo } from 'components/elements';
import logo from 'lib/img/logo-white.png';

import {
  BackLink,
  HamburgerMenu,
  Menu,
  MenuEntry,
  MobileMenu,
  NoWrapFullHeightGrid,
  Placeholder,
} from './Header.styles';

interface HeaderProps {
  linkList?: ReactElement[];
  isInMembersChildPage?: boolean;
  isPrivate?: boolean;
  isStatic?: boolean;
}

export const Header: FC<HeaderProps> = ({
  linkList,
  isInMembersChildPage = false,
  isPrivate = false,
  isStatic = false,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  function closeMobileMenu(): void {
    setIsMobileMenuOpen(false);
  }

  function openMobileMenu(e: MouseEvent): void {
    e.preventDefault();
    setIsMobileMenuOpen(true);
  }

  const menuEntryList = !linkList
    ? null
    : linkList.map((link, index) => (
        <MenuEntry key={index} onClick={closeMobileMenu}>
          {link}
        </MenuEntry>
      ));
  return (
    <>
      <Banner isTransparent={!isMobileMenuOpen}>
        <BannerContainer container>
          <Grid item xs={12}>
            <NoWrapFullHeightGrid container justify="space-between">
              <Grid item md={2}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Link to="/">
                      <Logo src={logo} alt="logo" />
                    </Link>
                  </Grid>
                  {isPrivate && isInMembersChildPage && (
                    <Grid item>
                      <BackLink to="/members">
                        <Button size="small">
                          <ChevronLeftIcon />
                          Revenir à votre espace membre
                        </Button>
                      </BackLink>
                    </Grid>
                  )}
                  {!isStatic && (
                    <Grid item>
                      <LanguageMenu />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Hidden smDown>
                <nav role="navigation">
                  <Menu>{menuEntryList}</Menu>
                </nav>
              </Hidden>
              <Hidden mdUp>
                <HamburgerMenu
                  aria-controls={isMobileMenuOpen ? 'mobile-menu' : undefined}
                  aria-haspopup="true"
                >
                  {isMobileMenuOpen ? (
                    <CloseIcon fontSize="large" onClick={closeMobileMenu} />
                  ) : (
                    <MenuIcon fontSize="large" onClick={openMobileMenu} />
                  )}
                </HamburgerMenu>
              </Hidden>
            </NoWrapFullHeightGrid>
          </Grid>
        </BannerContainer>
        <Hidden mdUp>
          <Popper open={isMobileMenuOpen} role={undefined} disablePortal>
            <ClickAwayListener onClickAway={closeMobileMenu}>
              <Fade in>
                <Banner isTransparent={!isMobileMenuOpen}>
                  <nav role="navigation">
                    <MobileMenu id="mobile-menu">{menuEntryList}</MobileMenu>
                  </nav>
                </Banner>
              </Fade>
            </ClickAwayListener>
          </Popper>
        </Hidden>
      </Banner>
      <Placeholder />
    </>
  );
};
