import { Action } from 'react-fetching-library';

import {
  PatchSettingsBody,
  PatchProfileBody,
  PatchAvatarBody,
} from 'lib/interfaces';

export function patchUser({
  userId,
  ...rest
}: PatchSettingsBody | PatchProfileBody | PatchAvatarBody): Action {
  return {
    method: 'PATCH',
    endpoint: `users/${userId}`,
    body: {
      ...rest,
    },
  };
}
