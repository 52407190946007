import { Action } from 'react-fetching-library';

export function postFile(file: File): Action {
  const formData = new FormData();
  formData.append('data', file, file.name);
  return {
    method: 'POST',
    endpoint: 'files',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: formData,
  };
}
