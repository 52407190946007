import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSuspenseQuery } from 'react-fetching-library';
import Hidden from '@material-ui/core/Hidden';

import { ErrorMessage } from 'components/ErrorMessage';
import { NotFound } from 'components/NotFound';
import { Page, PageIllustration } from 'components/Page';
import { getPostList, getPostTranslationList } from 'lib/api';
import { debugNews } from 'lib/debug';
import { MenuEntries } from 'lib/enums';
import { useTitle, useUserList } from 'lib/hooks';
import { Post as APIPost, TranslatedPost } from 'lib/interfaces';
import { PostPathMap } from './PostPathMap';

export const Post: FC<{}> = () => {
  const { slug } = useParams();
  const { t, i18n } = useTranslation('errors');
  const title = useTitle(MenuEntries.News);

  const { payload: translatedPostPayload, errorObject } = useSuspenseQuery<
    TranslatedPost[]
  >({
    params: {
      'filter[language_code]': i18n.language,
      'filter[url]': slug,
    },
    ...getPostTranslationList,
  });

  const { payload: postPayload } = useSuspenseQuery<APIPost[]>({
    params: {
      fields: 'id,illustration',
    },
    ...getPostList,
  });

  const { userList } = useUserList();

  let errorBlock: ReactElement;
  let illustration: ReactElement;
  let page: ReactElement;
  if (errorObject) {
    debugNews(errorObject);
    errorBlock = <ErrorMessage message={t('unknown-error')} />;
  } else if (translatedPostPayload.length === 0) {
    errorBlock = <NotFound />;
  } else if (postPayload) {
    try {
      const {
        article,
        contenu: body,
        titre: title,
        created_by: createdBy,
        created_on: createdOn,
      } = translatedPostPayload[0];
      const post = postPayload.find(item => item.id === parseInt(article, 10));
      if (!body && !title) {
        errorBlock = <span></span>;
      } else {
        illustration =
          post && post.illustration ? (
            <Hidden xsDown>
              <PageIllustration fileId={post.illustration} />
            </Hidden>
          ) : null;
        const author = userList
          ? userList.find(user => user.id === createdBy)
          : null;
        page = (
          <>
            <Page body={body} title={title} author={author} date={createdOn} />
            <PostPathMap postId={post.id} />
          </>
        );
      }
    } catch (err) {
      errorBlock = <ErrorMessage message={t('no-translation')} />;
      debugNews(err);
    }
  }

  return (
    <>
      <Helmet>{title}</Helmet>
      {illustration}
      {errorBlock ? errorBlock : page}
    </>
  );
};
