import styled from 'styled-components';

export const InformationList = styled.ul<{ smallMargin: boolean }>`
  list-style-type: none;
  margin-top: ${props =>
    props.smallMargin ? props.theme.spacing.s2 : props.theme.spacing.s4};
  padding: 0;

  a,
  a:visited {
    color: inherit;
  }

  a:focus,
  a:hover {
    color: ${props => props.theme.colors.primary()};
  }
`;
