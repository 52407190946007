import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from 'react-fetching-library';
import Typography from '@material-ui/core/Typography';

import { ThinTypography } from 'components/elements';
import { getContactInformation } from 'lib/api';
import { debugContact } from 'lib/debug';
import { ContactInformation } from 'lib/interfaces';

import { InformationList } from './ContactInformationList.styles';

interface ContactInformationListProps {
  smallMargin?: boolean;
}

export const ContactInformationList: FC<ContactInformationListProps> = ({
  smallMargin = false,
}) => {
  const { t } = useTranslation('errors');
  const { payload, errorObject } = useSuspenseQuery<ContactInformation>(
    getContactInformation,
  );

  let content: ReactElement;
  if (errorObject) {
    debugContact(errorObject);
    content = <Typography variant="body1">{t('unknown-error')}</Typography>;
  } else {
    const { adresse, email, lien_maps: lienMaps, nom, telephone } = payload;
    content = (
      <InformationList smallMargin={smallMargin}>
        <Typography component="li">
          <strong
            dangerouslySetInnerHTML={{
              __html: nom,
            }}
          />
        </Typography>
        <ThinTypography component="li">
          <a
            href={lienMaps}
            title={`${adresse} | Google Maps`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            {adresse}
          </a>
        </ThinTypography>
        {telephone !== '' && (
          <ThinTypography component="li">
            <a
              href={`tel:${telephone}`}
              dangerouslySetInnerHTML={{
                __html: telephone,
              }}
            />
          </ThinTypography>
        )}
        <ThinTypography component="li">
          <a
            href={`mailto:${email}?Subject=Contact%20depuis%20le%20site%20internet`}
            dangerouslySetInnerHTML={{
              __html: email,
            }}
          />
        </ThinTypography>
      </InformationList>
    );
  }
  return content;
};
