import { debugHelpers } from 'lib/debug';
import { QueryParameters } from 'lib/interfaces';

export function queryParametersParser(inputString: string): QueryParameters {
  const qp: QueryParameters = {};
  try {
    if (inputString[0] !== '?') {
      return;
    }
    const pairList = inputString.substr(1).split('&');
    pairList.forEach(pair => {
      const [key, value] = pair.split('=');
      qp[key] = decodeURIComponent(value);
    });
  } catch (err) {
    debugHelpers(err);
  }
  return qp;
}
