import React, { FC } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface InteractiveButton extends ButtonProps {
  disabled?: boolean;
  label?: string;
  loading: boolean;
  variant?: 'outlined' | 'text' | 'contained';
}

export const InteractiveButton: FC<InteractiveButton> = ({
  disabled = false,
  label = 'Enregistrer',
  loading,
  variant = 'outlined',
  ...rest
}) => {
  return (
    <>
      {loading ? (
        <CircularProgress size={32} />
      ) : (
        <Button
          color="primary"
          variant={variant}
          disabled={loading || disabled}
          {...rest}
        >
          {label}
        </Button>
      )}
    </>
  );
};
