import { Action } from 'react-fetching-library';

import { ContactFormState } from 'lib/interfaces';

export function postContactMessage({
  email,
  message,
  name,
}: ContactFormState): Action {
  return {
    method: 'POST',
    endpoint: 'items/contact',
    body: {
      email,
      message,
      nom: name,
    },
  };
}
