import React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components/Layout';
import { NotFound } from 'components/NotFound';
import { ContainerPage } from 'components/Page';
import { DEFAULT_LANGUAGE } from 'config';
import { debugRoute } from 'lib/debug';
import { AvailableLanguages } from 'lib/enums';
import { Contact } from 'pages/Contact';
import { Home } from 'pages/Home';
import { News, Post } from 'pages/News';
import { Search } from 'pages/Search';

export const LocalizedRoutes: React.FC<{}> = () => {
  const history = useHistory();
  const { lang: routeLang } = useParams();
  const { path, url } = useRouteMatch();
  const { i18n } = useTranslation();

  let content = (
    <Switch>
      <Route exact path={path} component={Home} />
      <Route exact path={`${path}/contact`} component={Contact} />
      <Route exact path={`${path}/search`} component={Search} />
      <Route exact path={`${path}/news`} component={News} />
      <Route exact path={`${path}/news/:slug`} component={Post} />
      <Route exact path={`${path}/:role`} component={ContainerPage} />
      <Route component={NotFound} status={404} />
    </Switch>
  );

  if (routeLang) {
    if (
      Object.values(AvailableLanguages).includes(
        routeLang as AvailableLanguages,
      )
    ) {
      if (routeLang !== i18n.language) {
        debugRoute(`Force language change to: ${routeLang}`);
        debugRoute(`current url: ${url}`);
        i18n.changeLanguage(routeLang);
      }
    } else {
      content = <NotFound />;
    }
  } else {
    const definedLanguage = defineLanguage(i18n.language);
    history.replace(`/${definedLanguage}`);
  }

  // TODO: use Helmet for meta and title
  // - role based pages
  // - posts
  return <Layout>{content}</Layout>;
};

function defineLanguage(detectedLanguage: string): AvailableLanguages {
  if (detectedLanguage && detectedLanguage.length >= 2) {
    const desiredLanguage = detectedLanguage.substring(
      0,
      2,
    ) as AvailableLanguages;
    if (Object.values(AvailableLanguages).includes(desiredLanguage)) {
      return desiredLanguage;
    }
  }
  return DEFAULT_LANGUAGE;
}
