import { FC, useContext } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';

import { getPostTranslationList } from 'lib/api';
import { PostTranslationContext } from 'lib/contexts';
import { TranslatedPost } from 'lib/interfaces';
import { AvailableLanguages } from 'lib/enums';

interface PostPathMapProps {
  postId: number;
}

export const PostPathMap: FC<PostPathMapProps> = ({ postId }) => {
  const { postId: postTranslationContextPostId, setPostPathMap } = useContext(
    PostTranslationContext,
  );

  const { payload: translatedPostList } = useSuspenseQuery<TranslatedPost[]>({
    params: {
      'filter[article]': postId,
      fields: 'url,language_code',
    },
    ...getPostTranslationList,
  });
  if (postId !== postTranslationContextPostId) {
    const newPostPathMap = new Map<AvailableLanguages, string>();
    translatedPostList.forEach(item => {
      newPostPathMap.set(item.language_code, item.url);
    });
    setPostPathMap(newPostPathMap, postId);
  }
  return null;
};
