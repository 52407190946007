import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from 'components/ErrorMessage';
import { ContainerPage } from 'components/Page';
import { getLanguageList, getTranslatorList, getUserList } from 'lib/api';
import { debugSearch } from 'lib/debug';
import { MenuEntries } from 'lib/enums';
import { useTitle } from 'lib/hooks';
import { Language, Translator, User } from 'lib/interfaces';
import { queryParametersParser } from 'lib/utils';

import { SearchEngine } from './SearchEngine';

export const Search: FC<{}> = () => {
  const { search } = useLocation();
  const { t, i18n } = useTranslation('errors');
  const title = useTitle(MenuEntries.Search);

  const {
    error: languageListError,
    errorObject: languageListErrorObject,
    payload: languageList,
  } = useQuery<Language[]>({
    params: {
      'filter[language_code]': i18n.language,
    },
    ...getLanguageList,
  });

  const {
    error: translatorListError,
    errorObject: translatorListErrorObject,
    payload: translatorListPayload,
  } = useQuery<Translator[]>(getTranslatorList);

  const {
    error: userListError,
    errorObject: userListErrorObject,
    payload: userList,
  } = useQuery<User[]>(getUserList);

  let content: ReactElement = null;
  if (languageListError || translatorListError || userListError) {
    debugSearch('languageList error', languageListErrorObject);
    debugSearch('translatorList error', translatorListErrorObject);
    debugSearch('userList error', userListErrorObject);
    content = <ErrorMessage message={t('unknown-error')} />;
  } else if (
    languageList &&
    translatorListPayload &&
    userList &&
    translatorListPayload.length > 0
  ) {
    const translatorList = translatorListPayload.filter(
      translator =>
        translator.habilitations && translator.habilitations.length > 0,
    );
    const qp = queryParametersParser(search);
    let initialFilter = '';
    let initialFrom = '';
    let initialTo = '';
    if (qp) {
      if (qp.q) {
        initialFilter = qp.q;
      }
      if (qp.from) {
        initialFrom = qp.from;
      }
      if (qp.to) {
        initialTo = qp.to;
      }
    }

    const { fromList, toList } = extractLanguageCodeList(translatorList);
    content = (
      <SearchEngine
        fromList={fromList}
        initialFilter={initialFilter}
        initialFrom={initialFrom}
        initialTo={initialTo}
        fullTranslatorList={formatTranslatorList(translatorList, userList)}
        languageMap={createLanguageMapFromLanguageList(languageList)}
        toList={toList}
      />
    );
  }
  return (
    <>
      <Helmet>{title}</Helmet>
      <ContainerPage bottomDivider forceRole="search" />
      {content}
    </>
  );
};

function createLanguageMapFromLanguageList(
  inputLanguageList: Language[],
): Map<string, string> {
  const languageMap = new Map<string, string>();
  inputLanguageList.forEach(
    language => (languageMap[language.code] = language.nom),
  );
  return languageMap;
}

function extractLanguageCodeList(
  translatorList: Translator[],
): { fromList: string[]; toList: string[] } {
  const dualList = translatorList.reduce(
    (out, translator) => {
      translator.habilitations.forEach(habilitation => {
        const { from, to } = habilitation;
        if (!out.fromList.includes(from)) {
          out.fromList.push(from);
        }
        if (!out.toList.includes(to)) {
          out.toList.push(to);
        }
      });
      return out;
    },
    { fromList: [], toList: [] },
  );
  return {
    fromList: dualList.fromList.sort(),
    toList: dualList.toList.sort(),
  };
}

function formatTranslatorList(
  translatorList: Translator[],
  userList: User[],
): Translator[] {
  const fullTranslatorList = translatorList.map(translator => {
    const user = userList.find(item => item.id === translator.membre);
    return {
      ...translator,
      ...user,
    };
  });
  return fullTranslatorList
    .map(translator => ({
      ...translator,
      fullName: `${translator.first_name.toLocaleLowerCase()} ${translator.last_name.toLocaleLowerCase()}`,
    }))
    .sort((a, b) => a.last_name.localeCompare(b.last_name));
}
