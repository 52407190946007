import React, { FC, FormEvent, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-fetching-library';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { debugLogin } from 'lib/debug';
import {
  FullWidthGrid,
  FullWidthTextField,
  XsContainer,
} from 'components/elements';
import { InteractiveButton } from 'components/InteractiveButton';
import { postPasswordResetRequest } from 'lib/api';

export const ResetPasswordRequestForm: FC<{}> = () => {
  const [email, setEmail] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  const { loading, mutate: sendRequest } = useMutation(
    postPasswordResetRequest,
  );

  async function onResetPasswordRequestSubmit(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    const { payload, error, errorObject } = await sendRequest(email);
    if (error && errorObject.code !== 107) {
      debugLogin(errorObject);
      enqueueSnackbar('Une erreur est survenue.', { variant: 'error' });
    } else if (payload || errorObject.code === 107) {
      enqueueSnackbar("Un email avec plus d'informations vous a été envoyé.", {
        variant: 'success',
      });
    }
  }

  return (
    <form onSubmit={onResetPasswordRequestSubmit}>
      <XsContainer
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FullWidthGrid item>
          <FullWidthTextField
            autoComplete="username"
            required
            placeholder="Email"
            variant="outlined"
            value={email}
            type="email"
            onChange={e => setEmail(e.currentTarget.value)}
          />
        </FullWidthGrid>
        <FullWidthGrid
          item
          container
          justify="center"
          direction="column"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <InteractiveButton
              loading={loading}
              label="Envoyer"
              variant="contained"
              type="submit"
            />
          </Grid>
          <Grid item>
            <Link to="login">Se connecter</Link>
          </Grid>
        </FullWidthGrid>
      </XsContainer>
    </form>
  );
};
