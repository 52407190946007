import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import GavelIcon from '@material-ui/icons/Gavel';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import TranslateIcon from '@material-ui/icons/Translate';

import { LandingGrid, MainTitle } from 'components/elements';
import { ContainerPage } from 'components/Page';
import { QuickAccess } from 'components/QuickAccess';
import { AvailableLanguages, MenuEntries } from 'lib/enums';
import { useTitle } from 'lib/hooks';
import logo from 'lib/img/logo-white.png';

import {
  Container,
  Cover,
  FullImage,
  Logo,
  SpacedGrid,
  TitleBox,
  WebsiteTitle,
} from './Home.styles';

const FRENCH_TITLE = 'Association Suisse des Traducteurs-Jurés';

export const Home: FC<{}> = () => {
  const { t, i18n } = useTranslation('home');
  const title = useTitle(MenuEntries.Home);
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://www.astj.ch/${i18n.language}`} />
        {title}
      </Helmet>
      <Cover
        container
        direction="column"
        justify="space-around"
        alignItems="center"
      >
        <FullImage />
        <TitleBox item>
          <Grid
            container
            direction="column"
            justify="center"
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Logo alt="logo" src={logo} />
            </Grid>
            <Grid item xs={12}>
              <WebsiteTitle variant="h1">{FRENCH_TITLE}</WebsiteTitle>
              {i18n.language !== AvailableLanguages.FR && (
                <WebsiteTitle variant="h3" isSubtitle>
                  {t('title')}
                </WebsiteTitle>
              )}
            </Grid>
          </Grid>
        </TitleBox>
      </Cover>
      <Container isXs={useMediaQuery(theme.breakpoints.down('xs'))}>
        <LandingGrid>
          <MainTitle variant="h4">{t('subtitle')}</MainTitle>
          <Grid container justify="center">
            <Grid item xs={12} xl={8}>
              <Grid container>
                <SpacedGrid isXs={useMediaQuery(theme.breakpoints.down('xs'))}>
                  <QuickAccess
                    content={t('profession_content')}
                    icon={TranslateIcon}
                    link={`${i18n.language}/the-profession`}
                    title={t('profession_title')}
                  />
                </SpacedGrid>
                <SpacedGrid isXs={useMediaQuery(theme.breakpoints.down('xs'))}>
                  <QuickAccess
                    content={t('search_content')}
                    icon={SearchIcon}
                    link={`${i18n.language}/search`}
                    title={t('search_title')}
                  />
                </SpacedGrid>
                <SpacedGrid isXs={useMediaQuery(theme.breakpoints.down('xs'))}>
                  <QuickAccess
                    content={t('association_content')}
                    icon={GavelIcon}
                    link={`${i18n.language}/about`}
                    title={t('association_title')}
                  />
                </SpacedGrid>
                <SpacedGrid isXs={useMediaQuery(theme.breakpoints.down('xs'))}>
                  <QuickAccess
                    content={t('pricing_content')}
                    icon={LocalOfferIcon}
                    link={`${i18n.language}/pricing`}
                    title={t('pricing_title')}
                  />
                </SpacedGrid>
                <SpacedGrid isXs={useMediaQuery(theme.breakpoints.down('xs'))}>
                  <QuickAccess
                    content={t('contact_content')}
                    icon={ModeCommentIcon}
                    link={`${i18n.language}/contact`}
                    title={t('contact_title')}
                  />
                </SpacedGrid>
                <SpacedGrid isXs={useMediaQuery(theme.breakpoints.down('xs'))}>
                  <QuickAccess
                    content={t('community_content')}
                    icon={PeopleIcon}
                    isNativeLink
                    link="/members"
                    title={t('community_title')}
                  />
                </SpacedGrid>
              </Grid>
            </Grid>
          </Grid>
        </LandingGrid>
        <span id="home"></span>
        <ContainerPage isEmbedded noShadow forceRole="home" />
      </Container>
    </>
  );
};
