import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CardContent, { CardContentProps } from '@material-ui/core/CardContent';

interface PostCardContentProps extends CardContentProps {
  isXs?: boolean;
}

export const Abstract = styled.div`
  div,
  p,
  span {
    margin: 0;
    padding: 0;
  }
`;

export const CardLink = styled(Link)`
  text-decoration: none;
`;

export const PostCardContent = styled<FC<PostCardContentProps>>(
  ({ isXs, ...rest }) => <CardContent {...rest} />,
)`
  height: calc(
    2 *
      ${props =>
        props.isXs ? props.theme.spacing.s20 : props.theme.spacing.s16}
  );
  overflow: hidden;
`;
