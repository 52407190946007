import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { FullHeightGrid, Section } from './elements';

export const NotFound: FC<{}> = () => {
  const { t } = useTranslation('errors');
  return (
    <>
      <Helmet>
        <title>ASTJ | 404 - {t('not-found_title')}</title>
      </Helmet>
      <FullHeightGrid>
        <Section>
          <Typography variant="h3" align="center">
            404
          </Typography>
          <br />
          <Typography variant="body1" align="center">
            {t('not-found_text')}
            <strong>{window.location.pathname}</strong>.
          </Typography>
        </Section>
      </FullHeightGrid>
    </>
  );
};
