import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ForumIcon from '@material-ui/icons/Forum';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { LandingGrid, Section, SubTitle } from 'components/elements';
import { QuickAccess } from 'components/QuickAccess';

import { LogoutGrid, SpaceGrid } from './Member.styles';
import { User as CurrentUser } from 'lib/utils';
import { API_HOST, EDITOR_ROLE_ID } from 'config';

export const Member: FC<{}> = () => {
  return (
    <Section>
      <SubTitle>Bienvenue dans votre espace membre</SubTitle>
      <LandingGrid>
        <Grid container justify="center">
          <Grid item xs={12} md={8} xl={6}>
            <Grid container>
              <SpaceGrid item xs={12} sm={6}>
                <QuickAccess
                  content="Enregistrez toutes les informations vous concernant"
                  icon={PersonIcon}
                  link="members/profile"
                  title="Mon Profil"
                />
              </SpaceGrid>
              <SpaceGrid item xs={12} sm={6}>
                <QuickAccess
                  content="Modifiez vos informations de connexion"
                  icon={SettingsIcon}
                  link="members/settings"
                  title="Mon compte"
                />
              </SpaceGrid>
              <SpaceGrid item xs={12} sm={6}>
                <QuickAccess
                  content="Échangez avec les autres membres de l'ASTJ"
                  icon={ForumIcon}
                  isNativeLink
                  link="/forum"
                  openInNewTab
                  title="Le forum"
                />
              </SpaceGrid>
              {CurrentUser.isAuthenticated &&
                CurrentUser.userRole === EDITOR_ROLE_ID && (
                  <SpaceGrid item xs={12} sm={6}>
                    <QuickAccess
                      content="Panel administrateur du site de l'ASTJ"
                      icon={AccountBalanceIcon}
                      isNativeLink
                      openInNewTab
                      link={`${API_HOST}/admin`}
                      title="Administration"
                    />
                  </SpaceGrid>
                )}
            </Grid>
          </Grid>
        </Grid>
        <LogoutGrid
          item
          container
          justify="center"
          direction="column"
          alignItems="center"
        >
          <Grid item>
            <Button color="primary" variant="contained" href="/logout">
              <ExitToAppIcon />
              &nbsp;Se déconnecter
            </Button>
          </Grid>
        </LogoutGrid>
      </LandingGrid>
    </Section>
  );
};
