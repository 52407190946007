import React, { FC } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import Card, { CardProps } from '@material-ui/core/Card';
import Grid, { GridProps } from '@material-ui/core/Grid';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ColorVariantEnum } from 'lib/enums';

interface PaddedGridProps extends GridProps {
  isHalf?: boolean;
}

interface DynamicCardProps extends CardProps {
  colored?: boolean;
}

type FullWidthTextFieldProps = TextFieldProps & {
  topAdornment?: boolean;
};

export const Banner = styled.header<{ isTransparent?: boolean }>`
  background: ${props =>
    props.isTransparent
      ? transparentize(0.05, props.theme.colors.black(ColorVariantEnum.dark))
      : props.theme.colors.black(ColorVariantEnum.dark)};
  backdrop-filter: blur(5px);
  color: ${props => props.theme.colors.white(ColorVariantEnum.dark)};
  position: fixed;
  width: 100vw;
  z-index: 99;
`;

export const BannerContainer = styled(Grid)`
  padding: 0 ${props => props.theme.spacing.s8};
`;

export const Block = styled(Grid)`
  margin-bottom: ${props => props.theme.spacing.s8};
`;

export const Logo = styled.img`
  height: ${props => props.theme.spacing.s12};
  padding: ${props => props.theme.spacing.s2} 0;
`;

export const DynamicCard = styled<FC<DynamicCardProps>>(
  ({ colored, ...rest }) => <Card {...rest} />,
)`
  cursor: pointer;
  transition: all 0.3s ease !important;

  &:hover {
    color: ${props =>
      props.colored
        ? props.theme.colors.primary(ColorVariantEnum.dark)
        : 'inherit'};
  }
`;

export const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

export const FullWidthGrid = styled(Grid)`
  width: 100%;
`;

export const FullWidthTextField = styled<FC<FullWidthTextFieldProps>>(
  ({ topAdornment, ...rest }) => <TextField {...rest} />,
)`
  width: 100%;

  & .MuiInputAdornment-root {
    align-self: ${props => (props.topAdornment ? 'flex-start' : 'unset')};
    margin-top: ${props =>
      props.topAdornment ? props.theme.spacing.s2 : 'unset'};
  }
`;

export const LandingGrid = styled.div`
  min-height: calc(
    75vh - ${props => props.theme.spacing.s8} -
      ${props => props.theme.spacing.s12}
  );
  padding-bottom: ${props => props.theme.spacing.s8};
`;

export const PaddedGrid = styled<FC<PaddedGridProps>>(({ isHalf, ...rest }) => (
  <Grid {...rest} />
))`
  padding-bottom: ${props => props.theme.spacing.s8};
  padding-right: ${props => props.theme.spacing.s8};
  padding-top: ${props => (props.isHalf ? '0' : props.theme.spacing.s8)};
  padding-left: ${props => (props.isHalf ? '0' : props.theme.spacing.s8)};
`;

export const MainTitle = styled(Typography)`
  color: ${props => props.theme.colors.primary(ColorVariantEnum.dark)};
  font-size: ${props => props.theme.font.size.larger};
  font-weight: ${props => props.theme.font.weight.regular};
  letter-spacing: 2px;
  margin-bottom: ${props => props.theme.spacing.s12};
  text-align: center;
  word-spacing: ${props => props.theme.spacing.s2};
`;

export const SubTitle = styled(Typography)`
  color: ${props => props.theme.colors.primary(ColorVariantEnum.dark)};
  font-size: ${props => props.theme.font.size.larger};
  font-weight: ${props => props.theme.font.weight.regular};
  margin-bottom: ${props => props.theme.spacing.s12};
  text-align: center;
`;

export const ThinTypography = styled(Typography)`
  font-weight: ${props => props.theme.font.weight.thin};
`;

export const Section = styled(Grid)`
  padding: ${props => props.theme.spacing.s8};
`;

export const ShadowGrid = styled(Grid)`
  box-shadow: 0 -15px 15px -15px hsla(0, 0%, 0%, 0.2);
`;

export const XsContainer = styled(Grid)`
  min-width: ${props => props.theme.spacing.xs};
`;
