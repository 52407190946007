import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';

import { LoginForm } from 'components/LoginForm';
import { Layout } from 'components/Layout';
import { Block, Section } from 'components/elements';

export const Login: FC<{}> = () => {
  return (
    <Layout>
      <Section
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Block item>
          <Typography variant="h6">Connexion à l&apos;espace membre</Typography>
        </Block>
        <Block item>
          <LoginForm />
        </Block>
      </Section>
    </Layout>
  );
};
