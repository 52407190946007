import React, { FC } from 'react';
import styled from 'styled-components';
import Grid, { GridProps } from '@material-ui/core/Grid';

import { ColorVariantEnum } from 'lib/enums';

interface ResponsiveFooterProps extends GridProps {
  isXs?: boolean;
}

export const Container = styled.footer`
  color: ${props => props.theme.colors.white()};
`;

export const FooterBanner = styled<FC<ResponsiveFooterProps>>(
  ({ isXs, ...rest }) => <Grid {...rest} />,
)`
  background: ${props => props.theme.colors.black(ColorVariantEnum.darker)};
  color: ${props => props.theme.colors.secondary(ColorVariantEnum.darker)};
  font-weight: ${props => props.theme.font.weight.thin};
  font-size: ${props => props.theme.font.size.small};
  padding: ${props => props.theme.spacing.s2} ${props => props.theme.spacing.s8};

  & > *:not(:first-child) {
    margin-top: ${props => (props.isXs ? props.theme.spacing.s2 : 0)};
  }

  a,
  a:visited {
    color: inherit;
    text-decoration: none;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }
`;

export const FooterLinkList = styled.ul`
  list-style-type: none;
  margin-top: ${props => props.theme.spacing.s2};
  padding: 0;

  a,
  a:visited {
    color: inherit;
    text-decoration: none;
    transition: 0.2s all ease;
  }

  a:focus,
  a:hover {
    color: ${props => props.theme.colors.primary()};
  }
`;

export const FooterMenu = styled<FC<ResponsiveFooterProps>>(
  ({ isXs, ...rest }) => <Grid {...rest} />,
)`
  background: ${props => props.theme.colors.black()};
  padding: ${props => props.theme.spacing.s4} ${props => props.theme.spacing.s8};

  & > *:not(:first-child) {
    margin-top: ${props => (props.isXs ? props.theme.spacing.s6 : 0)};
  }
`;
