import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ClientContextProvider } from 'react-fetching-library';
import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import 'normalize.css';

import { App } from 'components/App';
import { APP_ROOT } from 'config';
import { Client } from 'lib/api';
import { SnackbarProvider } from 'lib/contexts';
import { GlobalStyle, materialTheme, theme } from 'lib/style';
import * as serviceWorker from './serviceWorker';
import 'lib/i18n';

// To remove all previous JSS style from the head
// This is for pre-rendered pages
Array.from(document.querySelectorAll('[data-jss]')).forEach(elem =>
  elem.parentNode.removeChild(elem),
);

const rootElement = document.getElementById(APP_ROOT);
export function ReactApp() {
  return (
    <BrowserRouter>
      <ClientContextProvider client={Client}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <MaterialThemeProvider theme={materialTheme}>
              <SnackbarProvider>
                <>
                  <GlobalStyle />
                  <App />
                </>
              </SnackbarProvider>
            </MaterialThemeProvider>
          </ThemeProvider>
        </StylesProvider>
      </ClientContextProvider>
    </BrowserRouter>
  );
}
if (rootElement) {
  if (rootElement.hasChildNodes()) {
    hydrate(<ReactApp />, rootElement);
  } else {
    render(<ReactApp />, rootElement);
  }
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
