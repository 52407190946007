import React, { FC } from 'react';
import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { ColorVariantEnum } from 'lib/enums';

interface ContainerProps extends TypographyProps {
  centered?: boolean;
  isHover?: boolean;
}

export const Container = styled<FC<ContainerProps>>(
  ({ centered, isHover, ...rest }) => <Typography {...rest} />,
)`
  align-items: center;
  color: ${props =>
    props.isHover
      ? props.theme.colors.primary(ColorVariantEnum.light)
      : props.theme.colors.secondary(ColorVariantEnum.default)};
  display: flex;
  font-size: ${props => props.theme.font.size.small};
  font-weight: ${props => props.theme.font.weight.thin};
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  margin-top: ${props => props.theme.spacing.s2};
  margin-bottom: ${props => props.theme.spacing.s4};
  white-space: nowrap;

  svg {
    margin-right: ${props => props.theme.spacing.s1};
  }
`;
