import React, { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';

import { logout } from 'lib/api';
import { debugLogin } from 'lib/debug';
import { User as CurrentUser } from 'lib/utils';

export const Logout: FC<{}> = () => {
  const { mutate: sendLogoutRequest } = useMutation(logout);
  useEffect(() => {
    (async () => {
      if (CurrentUser.isAuthenticated) {
        const { payload, error, errorObject } = await sendLogoutRequest({
          userId: CurrentUser.userId,
        });
        if (error) {
          debugLogin(errorObject);
        } else if (payload) {
          CurrentUser.clearSession();
        }
      }
    })();
  });
  return <Redirect to="/" />;
};
