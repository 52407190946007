import React, { FC, ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface FormGroupProps {
  label?: string | ReactElement;
  fieldList: ReactElement[];
  pairNumber?: number;
}

export const FormGroup: FC<FormGroupProps> = ({
  label,
  fieldList,
  pairNumber = 0,
}) => {
  let pairFieldList: ReactElement[];
  let singleFieldList: ReactElement[] = fieldList;
  if (pairNumber > 0) {
    pairFieldList = fieldList.slice(0, 2 * pairNumber);
    singleFieldList = fieldList.slice(2 * pairNumber);
  }
  return (
    <Grid item container spacing={1} justify="center">
      {label && (
        <Grid item xs={10}>
          <Typography variant="subtitle1">{label}</Typography>
        </Grid>
      )}
      {pairFieldList &&
        pairFieldList.length > 0 &&
        pairFieldList.map((field, index) => (
          <Grid item xs={10} md={5} key={index}>
            {field}
          </Grid>
        ))}
      {singleFieldList &&
        singleFieldList.length > 0 &&
        singleFieldList.map((field, index) => (
          <Grid item xs={10} key={index}>
            {field}
          </Grid>
        ))}
    </Grid>
  );
};
