import { useQuery } from 'react-fetching-library';

import { EDITOR_ROLE_ID } from 'config';
import { User } from 'lib/interfaces';
import { getUserList } from 'lib/api';

export function useUserList(): {
  error: boolean;
  errorObject: any;
  userList: User[];
} {
  const { error, payload: userList, errorObject } = useQuery<User[]>({
    params: {
      'filter[role]': EDITOR_ROLE_ID,
      fields: 'id,first_name,last_name',
    },
    ...getUserList,
  });
  return {
    error,
    errorObject,
    userList,
  };
}
