import styled from 'styled-components';
import { transparentize } from 'polished';

import { ColorVariantEnum } from 'lib/enums';

export const Container = styled.div`
  cursor: pointer;
  background: ${props => props.theme.colors.white(ColorVariantEnum.dark)};
  position: relative;
  border-radius: ${props => props.theme.spacing.s1};
  overflow: hidden;
`;

export const Veil = styled.div<{ isHover: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props =>
    transparentize(0.9, props.theme.colors.black(ColorVariantEnum.dark))};
  visibility: ${props => (props.isHover ? 'visible' : 'hidden')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.white()};
  text-shadow: 1px 1px
    ${props => props.theme.colors.black(ColorVariantEnum.darker)};
`;
