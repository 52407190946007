import React, { FC, FormEvent, useRef, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { FullWidthTextField } from 'components/elements';
import { FormGroup } from 'components/FormGroup';
import { InteractiveButton } from 'components/InteractiveButton';
import { patchUser } from 'lib/api';
import { debugSettings } from 'lib/debug';
import { PatchSettingsBody } from 'lib/interfaces';
import { User as CurrentUser } from 'lib/utils';

interface SettingsProps {
  email: string;
}

export const SettingsForm: FC<SettingsProps> = ({ email }) => {
  const currentEmailRef = useRef(email);
  const [newEmail, setNewEmail] = useState<string>('');
  const [retypeNewEmail, setRetypeNewEmail] = useState<string>('');

  const [newPassword, setNewPassword] = useState<string>('');
  const [retypeNewPassword, setRetypeNewPassword] = useState<string>('');

  const { loading, mutate: sendSettingsRequest } = useMutation(patchUser);
  const { enqueueSnackbar } = useSnackbar();

  async function onSettingsFormSubmit(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (retypeNewPassword !== newPassword) {
      enqueueSnackbar('Les deux champs "mot de passe" ne correspondent pas.', {
        variant: 'warning',
      });
      return;
    }
    if (retypeNewEmail !== newEmail) {
      enqueueSnackbar('Les deux champs "email" ne correspondent pas.', {
        variant: 'warning',
      });
      return;
    }
    const settingsRequestBody: PatchSettingsBody = {
      userId: CurrentUser.userId,
    };
    if (newPassword) {
      settingsRequestBody.password = newPassword;
    }
    if (newEmail) {
      settingsRequestBody.email = newEmail;
    }
    const { payload, error, errorObject } = await sendSettingsRequest(
      settingsRequestBody,
    );
    if (error) {
      debugSettings(errorObject);
      enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
    } else if (payload) {
      enqueueSnackbar('Vos modifications ont bien été enregistrées', {
        variant: 'success',
      });
      if (newEmail) {
        currentEmailRef.current = newEmail;
      }
      setNewEmail('');
      setRetypeNewEmail('');
      setNewPassword('');
      setRetypeNewPassword('');
    }
  }

  return (
    <form onSubmit={onSettingsFormSubmit}>
      <Grid container direction="column" alignItems="center" spacing={4}>
        <FormGroup
          pairNumber={1}
          label={
            <>
              Email de connexion actuel :{' '}
              <Typography color="primary" variant="inherit">
                {currentEmailRef.current}
              </Typography>
            </>
          }
          fieldList={[
            <FullWidthTextField
              key={1}
              autoComplete="username"
              variant="outlined"
              placeholder="Entrez un nouvel email"
              type="email"
              onChange={e => setNewEmail(e.currentTarget.value)}
              value={newEmail}
            />,
            <FullWidthTextField
              key={2}
              autoComplete="username"
              variant="outlined"
              placeholder="Retapez le nouvel email"
              type="email"
              onChange={e => setRetypeNewEmail(e.currentTarget.value)}
              value={retypeNewEmail}
              error={retypeNewEmail !== newEmail}
              helperText={
                retypeNewEmail !== newEmail
                  ? 'Les deux champs ne corespondent pas'
                  : ''
              }
            />,
          ]}
        />
        <FormGroup
          label="Mot de passe"
          pairNumber={1}
          fieldList={[
            <FullWidthTextField
              key={1}
              autoComplete="new-password"
              variant="outlined"
              placeholder="Entrez un nouveau mot de passe"
              type="password"
              onChange={e => setNewPassword(e.currentTarget.value)}
              value={newPassword}
            />,
            <FullWidthTextField
              key={2}
              autoComplete="new-password"
              variant="outlined"
              placeholder="Retapez le nouveau mot de passe"
              type="password"
              onChange={e => setRetypeNewPassword(e.currentTarget.value)}
              value={retypeNewPassword}
              error={retypeNewPassword !== newPassword}
              helperText={
                retypeNewPassword !== newPassword
                  ? 'Les deux champs ne corespondent pas'
                  : ''
              }
            />,
          ]}
        />
        <FormGroup
          fieldList={[
            <InteractiveButton
              key={1}
              loading={loading}
              disabled={!newEmail && !newPassword}
              type="submit"
            />,
          ]}
        />
      </Grid>
    </form>
  );
};
