import { Action } from 'react-fetching-library';

export function postPasswordResetRequest(email: string): Action {
  return {
    method: 'POST',
    endpoint: 'auth/password/request',
    body: {
      email,
    },
  };
}
