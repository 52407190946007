import React, { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useSuspenseQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';

import { ErrorMessage } from 'components/ErrorMessage';
import { debugPage } from 'lib/debug';
import { Page as APIPage, TranslatedPage } from 'lib/interfaces';
import { getPageList, getPageTranslationList } from 'lib/api';

import { Page } from './Page';
import { PageIllustration } from './PageIllustration';

interface ContainerPageProps {
  bottomDivider?: boolean;
  forceRole?: string;
  isEmbedded?: boolean;
  noShadow?: boolean;
}

export const ContainerPage: FC<ContainerPageProps> = ({
  forceRole,
  isEmbedded,
  ...rest
}) => {
  const { lang, role } = useParams();
  const { t } = useTranslation('errors');

  const { payload, errorObject } = useSuspenseQuery<TranslatedPage[]>({
    params: {
      'filter[page]': forceRole || role,
      'filter[language_code]': lang,
      fields: 'contenu,titre',
    },
    ...getPageTranslationList,
  });

  const { payload: pagePayload } = useSuspenseQuery<APIPage[]>({
    params: {
      'filter[role]': forceRole || role,
      fields: 'illustration',
    },
    ...getPageList,
  });

  let errorBlock: ReactElement;
  let illustration: ReactElement;
  let page: ReactElement;
  if (pagePayload && pagePayload.length > 0 && pagePayload[0].illustration) {
    illustration = (
      <Hidden xsDown>
        <PageIllustration
          fileId={pagePayload[0].illustration}
          isEmbedded={isEmbedded}
        />
      </Hidden>
    );
  }
  if (errorObject) {
    debugPage(errorObject);
    errorBlock = <ErrorMessage message={t('unknown-error')} />;
  } else {
    try {
      const { contenu: body, titre: title } = payload[0];
      if (!body && !title) {
        errorBlock = <span></span>;
      } else {
        page = <Page body={body} title={title} {...rest} />;
      }
    } catch (err) {
      errorBlock = <ErrorMessage message={t('no-translation')} />;
      debugPage(err);
    }
  }

  return (
    <>
      {illustration}
      {errorBlock ? errorBlock : page}
    </>
  );
};
