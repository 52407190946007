import React, { FC, MouseEvent, useContext, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/it-ch';
import 'moment/locale/de-ch';
import 'moment/locale/en-gb';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PostTranslationContext } from 'lib/contexts';
import { debugLang } from 'lib/debug';
import { AvailableLanguages, MenuEntries } from 'lib/enums';

import {
  LanguageItem,
  LanguageList,
  LanguageSwitch,
} from './LanguageMenu.styles';

export const LanguageMenu: FC<{}> = () => {
  const { postPathMap } = useContext(PostTranslationContext);
  const history = useHistory();
  const location = useLocation();
  const [isLangMenuOpen, setIsLangMenuOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation('menu');
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { url } = useRouteMatch();

  function generateNewLocation(newLang: AvailableLanguages): string {
    debugLang('current location and url', location, url);
    let newLocation = '';
    const { pathname, search } = location;
    const path = generateNewPath(pathname, newLang);
    newLocation = `/${newLang}/${path}`;
    return `${newLocation}${search}`;
  }

  function generateNewPath(
    pathname: string,
    newLang: AvailableLanguages,
  ): string {
    const newPath = pathname.substring(url.length + 1);
    if (
      newPath.startsWith(`${MenuEntries.News}/`) &&
      postPathMap &&
      postPathMap.has(newLang)
    ) {
      return `${MenuEntries.News}/${postPathMap.get(newLang)}`;
    }
    return newPath;
  }

  function handleClose(): void {
    // FIXME: to be triggered when click on a link
    setIsLangMenuOpen(false);
  }

  function setLanguage(newLang: AvailableLanguages): void {
    debugLang(`application language set to: ${newLang}`);
    const newLocation = generateNewLocation(newLang);
    debugLang(`new location: ${newLocation}`);
    history.push(newLocation);
    setIsLangMenuOpen(false);
  }

  function setMomentLocale(lang: AvailableLanguages) {
    switch (lang) {
      case AvailableLanguages.DE:
        moment.locale('de-ch');
        break;
      case AvailableLanguages.IT:
        moment.locale('it-ch');
        break;
      case AvailableLanguages.FR:
        moment.locale('fr');
        break;
      default:
        moment.locale('en-gb');
        break;
    }
  }

  function toggleLangMenu(event: MouseEvent<HTMLElement>): void {
    event.preventDefault();
    event.stopPropagation();
    setIsLangMenuOpen(prevIsLangMenuOpen => !prevIsLangMenuOpen);
  }

  setMomentLocale(i18n.language as AvailableLanguages);

  return (
    <LanguageSwitch>
      <Button
        ref={anchorRef}
        aria-controls="language-selection"
        aria-haspopup="true"
        onClick={toggleLangMenu}
        size="small"
        title={t('change-language')}
      >
        {i18n.language} <ExpandMoreIcon fontSize="small" />
      </Button>
      <Popper
        anchorEl={anchorRef.current}
        open={isLangMenuOpen}
        transition
        placement="bottom-start"
        style={{ zIndex: 99 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <LanguageList id="language-selection" elevation={24} square>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <LanguageItem
                    onClick={() => setLanguage(AvailableLanguages.FR)}
                  >
                    Français
                  </LanguageItem>
                  <LanguageItem
                    onClick={() => setLanguage(AvailableLanguages.DE)}
                  >
                    Deutsch
                  </LanguageItem>
                  <LanguageItem
                    onClick={() => setLanguage(AvailableLanguages.IT)}
                  >
                    Italiano
                  </LanguageItem>
                  <LanguageItem
                    onClick={() => setLanguage(AvailableLanguages.RM)}
                  >
                    Rumantsch
                  </LanguageItem>
                  <LanguageItem
                    onClick={() => setLanguage(AvailableLanguages.EN)}
                  >
                    English
                  </LanguageItem>
                </MenuList>
              </ClickAwayListener>
            </LanguageList>
          </Grow>
        )}
      </Popper>
    </LanguageSwitch>
  );
};
