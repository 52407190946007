import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Section } from 'components/elements';

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <Section>
      <Typography align="center" variant="body1">
        {message}
      </Typography>
    </Section>
  );
};
