import React, { FC, FormEvent, Suspense, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';

import { InteractiveButton } from 'components/InteractiveButton';
import { FullWidthTextField } from 'components/elements';
import { FileUpload } from 'components/FileUpload';
import { FormGroup } from 'components/FormGroup';
import { EDITOR_ROLE_ID, ILLUSTRATION_SETTINGS } from 'config';
import { patchUser } from 'lib/api';
import { debugProfile } from 'lib/debug';
import { User as CurrentUser } from 'lib/utils';

interface ProfileFormProps {
  address?: string;
  avatar?: number;
  description?: string;
  firstName?: string;
  isEmailPublic?: boolean;
  lastName?: string;
  link?: string;
  phone?: string;
  role?: number;
}

export const ProfileForm: FC<ProfileFormProps> = ({
  address,
  avatar,
  description,
  firstName,
  isEmailPublic,
  lastName,
  link,
  phone,
  role,
}) => {
  const [newAvatar, setNewAvatar] = useState<number>(avatar);
  const [newAddress, setNewAddress] = useState<string>(address);
  const [newDescription, setNewDescription] = useState<string>(description);
  const [newFirstName, setNewFirstName] = useState<string>(firstName);
  const [newLastName, setNewLastName] = useState<string>(lastName);
  const [newLink, setNewLink] = useState<string>(link);
  const [newPhone, setNewPhone] = useState<string>(phone);
  const [newIsEmailPublic, setNewIsEmailPublic] = useState<boolean>(
    isEmailPublic,
  );

  const { enqueueSnackbar } = useSnackbar();
  const { loading, mutate: saveProfile } = useMutation(patchUser);

  async function onProfileFormSubmit(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    const { error, errorObject, payload } = await saveProfile({
      first_name: newFirstName,
      last_name: newLastName,
      telephone: newPhone,
      adresse: newAddress,
      email_public: newIsEmailPublic,
      description: newDescription,
      lien: newLink,
      userId: CurrentUser.userId,
    });
    if (error) {
      debugProfile(errorObject);
      enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
    } else if (payload) {
      enqueueSnackbar('Vos modifications ont bien été enregistrées', {
        variant: 'success',
      });
    }
  }

  return (
    <form onSubmit={onProfileFormSubmit}>
      <Grid container direction="column" alignItems="center" spacing={4}>
        <FormGroup
          label="Votre identité"
          pairNumber={1}
          fieldList={[
            <FullWidthTextField
              key={1}
              variant="outlined"
              placeholder="Prénom"
              onChange={e => setNewFirstName(e.currentTarget.value)}
              value={newFirstName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />,
            <FullWidthTextField
              key={2}
              variant="outlined"
              placeholder="Nom de famille"
              onChange={e => setNewLastName(e.currentTarget.value)}
              value={newLastName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />,
            <Suspense
              key={3}
              fallback={
                <div
                  style={{
                    width: '100%',
                    height: ILLUSTRATION_SETTINGS.SUMMARY,
                  }}
                />
              }
            >
              <FileUpload
                onSuccessfulUpload={setNewAvatar}
                fileId={newAvatar}
              />
            </Suspense>,
          ]}
        />
        {role !== EDITOR_ROLE_ID && (
          <>
            <FormGroup
              label="Vos coordonnées"
              fieldList={[
                <FormControlLabel
                  key={1}
                  control={
                    <Switch
                      checked={newIsEmailPublic}
                      onChange={e =>
                        setNewIsEmailPublic(e.currentTarget.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Laisser votre email public (sur votre fiche) ?"
                />,
                <FullWidthTextField
                  key={2}
                  variant="outlined"
                  placeholder="Numéro de téléphone"
                  onChange={e => setNewPhone(e.currentTarget.value)}
                  value={newPhone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />,
                <FullWidthTextField
                  key={3}
                  variant="outlined"
                  placeholder="Adresse"
                  onChange={e => setNewAddress(e.currentTarget.value)}
                  value={newAddress}
                  multiline
                  rows={4}
                  topAdornment
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />,
              ]}
            />

            <FormGroup
              label="Votre présentation"
              fieldList={[
                <FullWidthTextField
                  key={1}
                  variant="outlined"
                  placeholder="Une rapide description"
                  onChange={e => setNewDescription(e.currentTarget.value)}
                  value={newDescription}
                  multiline
                  rows={6}
                  topAdornment
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InfoIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />,
                <FullWidthTextField
                  key={1}
                  variant="outlined"
                  placeholder="Lien vers un site internet (URL complète, ex: https://mon-site.ch)"
                  onChange={e => setNewLink(e.currentTarget.value)}
                  value={newLink}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />,
              ]}
            />
          </>
        )}

        <FormGroup
          fieldList={[
            <InteractiveButton
              key={1}
              loading={loading}
              type="submit"
              // disabled={
              //   newFirstName === firstName &&
              //   newLastName === lastName &&
              //   newAddress === address &&
              //   newDescription === description &&
              //   newLink === link &&
              //   newPhone === phone &&
              //   newIsEmailPublic === isEmailPublic
              // }
            />,
          ]}
        />
      </Grid>
    </form>
  );
};
