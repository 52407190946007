import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RssFeedIcon from '@material-ui/icons/RssFeed';

import { ContactInformationList } from 'components/ContactInformationList';
import { ThinTypography } from 'components/elements';
import { API_ENDPOINT, API_HOST } from 'config';
import { FooterEntries1, FooterEntries2 } from 'lib/enums';

import { BalancedLink } from './TranslatedFooter.styles';
import { Footer } from '../Footer';

export const TranslatedFooter: React.FC<{}> = () => {
  const { i18n, t } = useTranslation('footer');

  const titleList = [t('title_1'), t('title_2')];
  const linkList = [FooterEntries1, FooterEntries2].map(entries =>
    Object.values(entries).map((value, index) => {
      switch (value) {
        case FooterEntries1.Home:
          return generateThinLink(
            <Link to="/" title={t(value)}>
              {t(value)}
            </Link>,
            index,
          );
        case FooterEntries1.Rss:
          return generateThinLink(
            <BalancedLink
              href={`${API_ENDPOINT}custom/${value}?lang=${i18n.language}`}
              target="_blank"
              rel="noopener noreferrer"
              title={t(value)}
            >
              <RssFeedIcon fontSize="small" />
              {t(value)}
            </BalancedLink>,
            index,
          );
        case FooterEntries2.Members:
          return generateThinLink(
            <Link to={`/${value}`} title={t(value)}>
              {t(value)}
            </Link>,
            index,
          );
        case FooterEntries2.Admin:
          return generateThinLink(
            <a
              href={`${API_HOST}/admin`}
              target="_blank"
              rel="noopener noreferrer"
              title={t(value)}
            >
              {t(value)}
            </a>,
            index,
          );
        default:
          return generateThinLink(
            <Link to={`/${i18n.language}/${value}`} title={t(value)}>
              {t(value)}
            </Link>,
            index,
          );
      }
    }),
  );

  return (
    <Footer
      informationList={<ContactInformationList smallMargin />}
      linkList={linkList}
      titleList={titleList}
    />
  );
};

function generateThinLink(link: ReactElement, index: number): ReactElement {
  return (
    <ThinTypography variant="body2" component="li" key={index}>
      {link}
    </ThinTypography>
  );
}
