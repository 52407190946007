import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Section, ShadowGrid } from 'components/elements';
import { PostInformation } from 'components/PostInformation';
import { Divider } from 'components/Divider';
import { useFormatting } from 'lib/hooks';
import { User } from 'lib/interfaces';

import { Body } from './Page.styles';

export interface PageProps {
  author?: User;
  body: string;
  bottomDivider?: boolean;
  date?: string;
  noShadow?: boolean;
  title: string;
}

export const Page: FC<PageProps> = ({
  author,
  body,
  bottomDivider = false,
  date,
  noShadow,
  title,
}) => {
  const PageGrid = noShadow ? Grid : ShadowGrid;
  const content = useFormatting(body);
  return (
    <PageGrid container justify="center">
      <Grid item xs={12} md={10}>
        <Section>
          <Typography variant="h3" component="h1" align="center">
            {title}
          </Typography>
          {author && (
            <PostInformation author={author} createdOn={date} centered />
          )}
          <Body className="ql-snow">{content}</Body>
        </Section>
      </Grid>
      {bottomDivider && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </PageGrid>
  );
};
