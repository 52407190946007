import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { PrivateMenuEntries } from 'lib/enums';

import { Header } from '../Header';

const PRIVATE_MENU_TITLE_MAP = new Map<PrivateMenuEntries, string>([
  [PrivateMenuEntries.Forum, 'Forum'],
  [PrivateMenuEntries.Profile, 'Profil'],
  [PrivateMenuEntries.Settings, 'Compte'],
  [PrivateMenuEntries.Logout, 'Se déconnecter'],
]);

export const PrivateHeader: FC<{}> = () => {
  const { pathname } = useLocation();
  const isInMembersChildPage = !pathname.endsWith('/members');

  const linkList = Object.values(PrivateMenuEntries).map(value => {
    switch (value) {
      case PrivateMenuEntries.Forum:
        return (
          <a
            title={PRIVATE_MENU_TITLE_MAP.get(value)}
            href="/forum"
            target="_blank"
            rel="noopener noreferrer"
          >
            {PRIVATE_MENU_TITLE_MAP.get(value)}
          </a>
        );
      case PrivateMenuEntries.Logout:
        return (
          <NavLink
            className="search"
            title={PRIVATE_MENU_TITLE_MAP.get(value)}
            to={`/${value}`}
          >
            <ExitToAppIcon />
          </NavLink>
        );
      default:
        return (
          <NavLink
            exact
            title={PRIVATE_MENU_TITLE_MAP.get(value)}
            to={`/members/${value}`}
          >
            {PRIVATE_MENU_TITLE_MAP.get(value)}
          </NavLink>
        );
    }
  });
  return (
    <Header
      isStatic
      isPrivate
      isInMembersChildPage={isInMembersChildPage}
      linkList={linkList}
    />
  );
};
