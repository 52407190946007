import React, { FC, ReactElement } from 'react';

import { Container } from './FallbackIllustration.styles';

interface FallbackIllustrationProps {
  height: number;
  icon: ReactElement;
}

export const FallbackIllustration: FC<FallbackIllustrationProps> = ({
  height,
  icon,
}) => <Container height={height}>{icon}</Container>;
