import React, { FC, ReactElement } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import {
  Container,
  FooterBanner,
  FooterLinkList,
  FooterMenu,
} from './Footer.styles';

export interface FooterProps {
  informationList?: ReactElement;
  linkList?: ReactElement[][];
  titleList?: string[];
}

export const Footer: FC<FooterProps> = ({
  informationList = null,
  linkList,
  titleList,
}) => {
  const theme = useTheme();

  let firstTitle = '';
  let secondTitle = '';
  if (titleList && titleList.length === 2) {
    [firstTitle, secondTitle] = titleList;
  }
  let firstColumnLinkList: ReactElement[] = null;
  let secondColumnLinkList: ReactElement[] = null;
  if (linkList && linkList.length === 2) {
    [firstColumnLinkList, secondColumnLinkList] = linkList;
  }
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <FooterMenu
            container
            isXs={useMediaQuery(theme.breakpoints.down('xs'))}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">{firstTitle}</Typography>
              <FooterLinkList>{firstColumnLinkList}</FooterLinkList>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">{secondTitle}</Typography>
              <FooterLinkList>{secondColumnLinkList}</FooterLinkList>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">ASTJ</Typography>
              {informationList}
            </Grid>
          </FooterMenu>
          <FooterBanner
            container
            justify="space-between"
            isXs={useMediaQuery(theme.breakpoints.down('xs'))}
          >
            <Grid item>
              &copy; {moment().year()} ASTJ - All rights reserved
            </Grid>
            <Grid item>
              Design &amp; development by{' '}
              <a
                href="https://paul.landazuri.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Paul Landázuri
              </a>
            </Grid>
          </FooterBanner>
        </Grid>
      </Grid>
    </Container>
  );
};
