import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Layout } from 'components/Layout';
import { NotFound } from 'components/NotFound';
import { PrivateRoute } from 'components/PrivateRoute';
import { Member } from 'pages/Member';
import { Profile } from 'pages/Profile';
import { Settings } from 'pages/Settings';

export const PrivateRoutes: FC<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <Layout isPrivate>
      <Switch>
        <PrivateRoute exact path={path} component={Member} />
        <PrivateRoute exact path={`${path}/settings`} component={Settings} />
        <PrivateRoute exact path={`${path}/profile`} component={Profile} />
        <Route component={NotFound} status={404} />
      </Switch>
    </Layout>
  );
};
