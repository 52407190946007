import styled from 'styled-components';

export const BalancedLink = styled.a`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${props => props.theme.spacing.s1};
    margin-top: -${props => props.theme.spacing.s1};
  }
`;
