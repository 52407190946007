import styled from 'styled-components';

export const SearchTable = styled.table`
  margin-bottom: ${props => props.theme.spacing.s4};
  text-align: left;
  vertical-align: middle;

  tr:not(:first-of-type) td {
    padding-top: ${props => props.theme.spacing.s6};
  }

  td:first-of-type {
    padding-right: ${props => props.theme.spacing.s4};
    vertical-align: bottom;
  }
`;
