import styled from 'styled-components';

import { ColorVariantEnum } from 'lib/enums';

export const Container = styled.div<{ height: number }>`
  align-items: 'center';
  background: ${props => props.theme.colors.secondary(ColorVariantEnum.light)};
  display: flex;
  justify-content: 'center';
  height: ${props => props.height}px;

  svg {
    color: ${props => props.theme.colors.black(ColorVariantEnum.dark)};
    height: 100%;
    width: 100%;
  }
`;
