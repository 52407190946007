import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import PersonIcon from '@material-ui/icons/Person';

import { postContactMessage } from 'lib/api';
import { debugContact } from 'lib/debug';
import { ContactFormState } from 'lib/interfaces';

import { FormButton, LabelIcon, PaperField } from './ContactForm.styles';

const initialState: ContactFormState = {
  email: '',
  message: '',
  name: '',
};

export const ContactForm: FC<{}> = () => {
  const [state, setState] = useState<ContactFormState>(initialState);
  const { t } = useTranslation('contact');
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: sendContactMessage } = useMutation(postContactMessage);

  async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    for (const prop in state) {
      if (!state[prop]) {
        enqueueSnackbar(`${t('field_error')} ${prop}`, {
          variant: 'warning',
        });
        return;
      }
    }
    const { error, errorObject } = await sendContactMessage(state);
    debugContact('message sent with the following data', state);
    if (error) {
      enqueueSnackbar(t('send_error'), {
        variant: 'error',
      });
      debugContact('error received:', errorObject);
      return;
    }
    setState(initialState);
    enqueueSnackbar(`${t('message_sent')}`, { variant: 'success' });
    return;
  }

  function handleChange(prop: keyof ContactFormState) {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState({ ...state, [prop]: e.target.value });
    };
  }

  function handleBlur(prop: keyof ContactFormState) {
    return () => {
      setState({ ...state, [prop]: state[prop].trim() });
    };
  }

  const { email, message, name } = state;
  return (
    <form onSubmit={onFormSubmit}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <PaperField>
            <LabelIcon>
              <PersonIcon titleAccess={t('name')} />
            </LabelIcon>
            <InputBase
              placeholder={t('name')}
              name="name"
              onChange={handleChange('name')}
              onBlur={handleBlur('name')}
              required
              value={name}
            />
          </PaperField>
          <PaperField>
            <LabelIcon>
              <AlternateEmailIcon titleAccess={t('email')} />
            </LabelIcon>
            <InputBase
              placeholder={t('email')}
              name="email"
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
              type="email"
              required
              value={email}
            />
          </PaperField>
          <PaperField notCentered>
            <LabelIcon>
              <InsertCommentIcon titleAccess={t('message')} />
            </LabelIcon>
            <InputBase
              placeholder={t('message')}
              name="message"
              onChange={handleChange('message')}
              onBlur={handleBlur('message')}
              required
              rows={5}
              multiline
              value={message}
            />
          </PaperField>
          <FormButton type="submit" variant="contained" color="primary">
            {t('submit')}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );
};
