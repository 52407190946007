import React, { FC, ReactElement } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';

import { getFile } from 'lib/api';
import { File } from 'lib/interfaces';

import { Container, FullImage } from './PageIllustration.styles';
import { debugPage } from 'lib/debug';

interface PageIllustrationProps {
  fileId: number;
  isEmbedded?: boolean;
}

export const PageIllustration: FC<PageIllustrationProps> = ({
  fileId,
  isEmbedded,
}) => {
  const { payload } = useSuspenseQuery<File>({
    id: fileId,
    ...getFile,
  });
  let img: ReactElement = null;
  if (payload) {
    debugPage(payload);
    try {
      const {
        data: { full_url: fullUrl },
        title,
      } = payload;
      img = (
        <Container isEmbedded={isEmbedded}>
          <FullImage imagePath={fullUrl} title={title} />
        </Container>
      );
    } catch (err) {
      debugPage(err);
    }
  }
  return img;
};
