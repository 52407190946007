import styled from 'styled-components';
import { lighten } from 'polished';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Typography from '@material-ui/core/Typography';

import { ColorVariantEnum } from 'lib/enums';

export const ChevronCell = styled.td`
  font-size: ${props => props.theme.font.size.smaller};
  line-height: 0;
`;

export const FullCardHeader = styled(Grid)`
  background: ${props =>
    lighten(0.1, props.theme.colors.secondary(ColorVariantEnum.light))};
`;

export const ResponsiveCardContent = styled(CardContent)`
  background: ${props =>
    lighten(0.1, props.theme.colors.secondary(ColorVariantEnum.light))};
`;

export const FullCardHeaderSummary = styled(Grid)`
  margin-top: ${props => props.theme.spacing.s6};
`;

export const HabilitationBlock = styled.div`
  margin-top: ${props => props.theme.spacing.s6};
`;

export const HabilitationEntry = styled(Typography)`
  white-space: nowrap;
`;

export const HabilitationTable = styled.table`
  border-spacing: 0;
  color: ${props => props.theme.colors.secondary(ColorVariantEnum.darker)};
  margin-top: -3px;
`;

export const ModalDivider = styled(Divider)`
  margin-bottom: ${props => props.theme.spacing.s4};
  margin-top: ${props => props.theme.spacing.s4};
`;

export const SummaryCardContent = styled(CardContent)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: calc(2 * ${props => props.theme.spacing.s20});
  justify-content: space-between;
`;

export const SummaryCardBody = styled.div`
  height: ${props => props.theme.spacing.s20};
`;

export const SummaryHabilitationWrap = styled.div`
  position: relative;
`;

export const SummaryMoreHorizIcon = styled(MoreHorizIcon)`
  position: absolute;
  display: block;
  height: 10px;
  width: 10px;
  bottom: -10px;
  left: 46%;
`;

export const SummaryName = styled.div`
  flex-grow: 1;
`;
