import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import RefreshIcon from '@material-ui/icons/Refresh';
import TranslateIcon from '@material-ui/icons/Translate';
import Typography from '@material-ui/core/Typography';

import { PaddedGrid } from 'components/elements';
import { Colored } from 'components/Colored';
import { debugSearch } from 'lib/debug';
import { Translator } from 'lib/interfaces';

import { TranslatorCard } from '../TranslatorCard';

import { SearchTable } from './SearchEngine.styles';

interface SearchEngineProps {
  fromList: string[];
  initialFilter?: string;
  initialFrom?: string;
  initialTo?: string;
  fullTranslatorList: Translator[];
  languageMap: Map<string, string>;
  toList: string[];
}

interface SearchEngineState {
  filter: string;
  from: string;
  lastActionType: SearchEngineAction['type'];
  matchingTranslatorList: Translator[];
  to: string;
}

type SearchEngineAction =
  | { type: 'reset' }
  | { type: 'changeFilter'; filter: string }
  | { type: 'selectFromLanguage'; from: string }
  | { type: 'selectToLanguage'; to: string };

export const SearchEngine: FC<SearchEngineProps> = ({
  fromList,
  initialFilter = '',
  initialFrom = '',
  initialTo = '',
  fullTranslatorList,
  languageMap,
  toList,
}) => {
  const initialState: SearchEngineState = {
    filter: initialFilter,
    from: initialFrom,
    lastActionType: 'reset',
    matchingTranslatorList: applyFilters(
      fullTranslatorList,
      initialFilter,
      initialFrom,
      initialTo,
    ),
    to: initialTo,
  };

  function searchEnginReducer(
    previousState: SearchEngineState,
    action: SearchEngineAction,
  ): SearchEngineState {
    debugSearch('action dispatched:', action);
    if (action.type === 'reset') {
      return {
        filter: '',
        from: '',
        lastActionType: 'reset',
        matchingTranslatorList: fullTranslatorList,
        to: '',
      };
    }
    let newFilter = previousState.filter;
    let newFrom = previousState.from;
    let newTo = previousState.to;
    switch (action.type) {
      case 'changeFilter':
        newFilter = action.filter;
        break;
      case 'selectFromLanguage':
        newFrom = action.from;
        break;
      case 'selectToLanguage':
        newTo = action.to;
        break;
      default:
        throw new Error();
    }
    debugSearch(
      'applying following filters',
      `name: ${newFilter} | `,
      `from: ${newFrom} | `,
      `to: ${newTo}`,
    );
    const newList = applyFilters(fullTranslatorList, newFilter, newFrom, newTo);
    return {
      filter: newFilter,
      from: newFrom,
      lastActionType: action.type,
      matchingTranslatorList: newList,
      to: newTo,
    };
  }

  const memoizedReducer = useCallback(searchEnginReducer, []);
  const [state, dispatch] = useReducer(memoizedReducer, initialState);
  const { filter, from, matchingTranslatorList, to } = state;
  const { t } = useTranslation('search');

  function onFilterChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    dispatch({
      type: 'changeFilter',
      filter: e.currentTarget.value,
    });
  }

  function onFormSubmit(e: FormEvent): void {
    e.preventDefault();
    e.stopPropagation();
  }

  function onLanguageChange(field: 'from' | 'to') {
    return (e: ChangeEvent<{ value: string }>) => {
      const lang = e.target.value;

      switch (field) {
        case 'from':
          if (lang !== 'fr') {
            dispatch({
              type: 'selectToLanguage',
              to: 'fr',
            });
          }
          return dispatch({
            type: 'selectFromLanguage',
            from: lang,
          });
        case 'to':
          if (lang !== 'fr') {
            dispatch({
              type: 'selectFromLanguage',
              from: 'fr',
            });
          }
          return dispatch({
            type: 'selectToLanguage',
            to: lang,
          });
      }
    };
  }

  function onReset(e: FormEvent): void {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: 'reset' });
  }

  function renderResults() {
    if (matchingTranslatorList.length > 0) {
      return matchingTranslatorList.map(translator => (
        <PaddedGrid
          isHalf
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          key={translator.id}
        >
          <TranslatorCard {...translator} languageMap={languageMap} />
        </PaddedGrid>
      ));
    }
    return <>{t('no-results')}</>;
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <form
          autoComplete="off"
          noValidate
          onSubmit={onFormSubmit}
          onReset={onReset}
        >
          <Grid container justify="center">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <SearchTable>
                    <tbody>
                      <tr>
                        <td>
                          <Colored>
                            <Grid
                              container
                              alignItems="center"
                              spacing={2}
                              justify="flex-end"
                            >
                              <Grid item>
                                <Typography variant="subtitle1">
                                  {t('from-legend')}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <TranslateIcon />
                              </Grid>
                            </Grid>
                          </Colored>
                        </td>
                        <td>
                          <FormControl>
                            <InputLabel htmlFor="language-from">
                              {t('from')}
                            </InputLabel>
                            <NativeSelect
                              value={from}
                              onChange={onLanguageChange('from')}
                              inputProps={{
                                id: 'language-from',
                              }}
                            >
                              <option value="" />
                              {fromList.map((itm, index) => (
                                <option value={itm} key={index}>
                                  {languageMap[itm]}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Colored>
                            <Grid
                              container
                              alignItems="center"
                              spacing={2}
                              justify="flex-end"
                            >
                              <Grid item>
                                <Typography variant="subtitle1">
                                  {t('to-legend')}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <TranslateIcon />
                              </Grid>
                            </Grid>
                          </Colored>
                        </td>
                        <td>
                          <FormControl>
                            <InputLabel htmlFor="language-to">
                              {t('to')}
                            </InputLabel>
                            <NativeSelect
                              value={to}
                              onChange={onLanguageChange('to')}
                              inputProps={{
                                id: 'language-to',
                              }}
                            >
                              <option value="" />
                              {toList.map((itm, index) => (
                                <option value={itm} key={index}>
                                  {languageMap[itm]}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Colored>
                            <Grid
                              container
                              alignItems="center"
                              spacing={2}
                              justify="flex-end"
                            >
                              <Grid item>
                                <Typography variant="subtitle1">
                                  {t('name-legend')}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <PersonIcon />
                              </Grid>
                            </Grid>
                          </Colored>
                        </td>
                        <td>
                          <TextField
                            label={t('name')}
                            onChange={onFilterChange}
                            value={filter}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Colored>
                            <Grid
                              container
                              alignItems="center"
                              spacing={2}
                              justify="flex-end"
                            >
                              <Grid item>
                                <RefreshIcon />
                              </Grid>
                            </Grid>
                          </Colored>
                        </td>
                        <td>
                          <Button type="reset">{t('reset')}</Button>
                        </td>
                      </tr>
                    </tbody>
                  </SearchTable>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <PaddedGrid
          container
          justify={matchingTranslatorList.length > 0 ? 'flex-start' : 'center'}
        >
          {renderResults()}
        </PaddedGrid>
      </Grid>
    </Grid>
  );
};

function applyFilters(
  translatorList: Translator[],
  nameFilter: string,
  fromFilter: string,
  toFilter: string,
): Translator[] {
  let newList = translatorList;
  if (nameFilter) {
    nameFilter = nameFilter.toLocaleLowerCase();
    newList = newList.filter(translator =>
      translator.fullName.includes(nameFilter),
    );
  }
  if (fromFilter || toFilter) {
    newList = newList.filter(translator => {
      if (fromFilter && toFilter) {
        return translator.habilitations.some(
          habilitation =>
            habilitation.from === fromFilter && habilitation.to === toFilter,
        );
      }
      return translator.habilitations.some(habilitation =>
        fromFilter
          ? habilitation.from === fromFilter
          : habilitation.to === toFilter,
      );
    });
  }
  return newList;
}
