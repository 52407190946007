import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ColorVariantEnum } from 'lib/enums';
import { FullHeightGrid } from 'components/elements';

export const BackLink = styled(Link)`
  padding-left: ${props => props.theme.spacing.s6};

  button {
    white-space: nowrap;
    text-transform: none;
    color: ${props => props.theme.colors.white(ColorVariantEnum.dark)};
    font-weight: ${props => props.theme.font.weight.thin};
  }
`;

export const HamburgerMenu = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

export const Menu = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  list-style: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
`;

export const MenuEntry = styled.li`
  padding: 0;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  a {
    color: inherit !important;
    display: block;
    font-weight: ${props => props.theme.font.weight.thin};
    font-size: ${props => props.theme.font.size.small};
    letter-spacing: 0.1em;
    padding: ${props => props.theme.spacing.s2}
      ${props => props.theme.spacing.s4};
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &::after {
      left: 0;
      bottom: -${props => props.theme.spacing.s1};
      width: 100%;
      height: 2px;
      background: ${props => props.theme.colors.white()};
      content: '';
      opacity: 0;
      position: absolute;
      transition: all 0.2s ease-in-out;
    }

    &:visited,
    &:active {
      color: inherit !important;
    }

    &:hover::after {
      opacity: 1;
    }
    &.search:hover::after {
      opacity: 0;
    }
  }
`;

export const MobileMenu = styled.ul`
  list-style: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;

  li {
    margin: ${props => props.theme.spacing.s2} 0;
    padding-right: ${props => props.theme.spacing.s4}!important;
    text-align: right;
    width: auto;

    a::after {
      width: auto;
    }
  }
`;

export const NoWrapFullHeightGrid = styled(FullHeightGrid)`
  flex-wrap: nowrap;
`;

export const Placeholder = styled.div`
  height: ${props => props.theme.spacing.s12};
  margin-bottom: ${props => props.theme.spacing.s5};
`;
