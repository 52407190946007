import React, { FC, FormEvent, ReactElement, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useMutation, useSuspenseQuery } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';

import {
  FullWidthGrid,
  FullWidthTextField,
  XsContainer,
} from 'components/elements';
import { InteractiveButton } from 'components/InteractiveButton';
import { authenticate, getCurrentUser } from 'lib/api';
import { debugLogin } from 'lib/debug';
import { LoginPayload, User } from 'lib/interfaces';
import { User as CurrentUser, queryParametersParser } from 'lib/utils';

export const LoginForm: FC<{}> = () => {
  const { search } = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { loading, mutate: sendLoginRequest } = useMutation(authenticate);

  const {
    payload: currentUserPayload,
    error: currentUserError,
  } = useSuspenseQuery<User>({
    params: {
      fields: 'id,role',
    },
    ...getCurrentUser,
  });
  if (!currentUserError && currentUserPayload) {
    CurrentUser.authenticate({
      authMethod: 'cookie',
      user: currentUserPayload,
    });
    redirectAfterLogin();
  }

  let content: ReactElement;

  function redirectAfterLogin() {
    const qp = queryParametersParser(search);
    if (qp && qp.redirect) {
      window.location.href = qp.redirect;
    }
    content = <Redirect to="/members" />;
  }

  async function onLoginFormSubmit(e: FormEvent) {
    e.preventDefault();
    e.stopPropagation();
    const { payload, error, errorObject } = await sendLoginRequest({
      email,
      password,
    });
    if (error) {
      debugLogin(errorObject);
      enqueueSnackbar('Email ou mot de passe invalide', { variant: 'error' });
    } else if (payload) {
      const loginPayload: LoginPayload = {
        authMethod: 'cookie',
        user: payload.user,
      };
      CurrentUser.authenticate(loginPayload);
      setEmail('');
      redirectAfterLogin();
    }
  }

  if (!CurrentUser.isAuthenticated) {
    content = (
      <form onSubmit={onLoginFormSubmit}>
        <XsContainer
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <FullWidthGrid item>
            <FullWidthTextField
              autoComplete="username"
              required
              placeholder="Email"
              variant="outlined"
              value={email}
              type="email"
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </FullWidthGrid>
          <FullWidthGrid item>
            <FullWidthTextField
              autoComplete="current-password"
              required
              placeholder="Mot de passe"
              variant="outlined"
              type="password"
              value={password}
              onChange={e => setPassword(e.currentTarget.value)}
            />
          </FullWidthGrid>
          <FullWidthGrid
            item
            container
            justify="center"
            direction="column"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <InteractiveButton
                loading={loading}
                label="Se connecter"
                variant="contained"
                type="submit"
              />
            </Grid>
            <Grid item>
              <Link to="reset-password">Mot de passe oublié ?</Link>
            </Grid>
          </FullWidthGrid>
        </XsContainer>
      </form>
    );
  } else {
    redirectAfterLogin();
  }
  return content;
};
