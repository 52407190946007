import React, { FC, ReactElement } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import Grid from '@material-ui/core/Grid';

import { Section, SubTitle } from 'components/elements';
import { ErrorMessage } from 'components/ErrorMessage';
import { SettingsForm } from 'components/SettingsForm';
import { getCurrentUser } from 'lib/api';
import { debugSettings } from 'lib/debug';
import { User } from 'lib/interfaces';

export const Settings: FC<{}> = () => {
  let content: ReactElement;

  const {
    payload: currentUserPayload,
    error: currentUserError,
    errorObject: currentUserErrorObject,
  } = useSuspenseQuery<User>({
    params: {
      fields: 'email',
    },
    ...getCurrentUser,
  });
  if (currentUserError) {
    debugSettings(currentUserErrorObject);
    content = <ErrorMessage message="Une erreur est survenue" />;
  } else if (currentUserPayload) {
    content = <SettingsForm email={currentUserPayload.email} />;
  }

  return (
    <Section container alignItems="center" justify="center">
      <Grid item xs={12}>
        <SubTitle>Informations de connexion</SubTitle>
      </Grid>
      <Grid container item xs={12} md={8} xl={6}>
        {content}
      </Grid>
    </Section>
  );
};
