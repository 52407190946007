import { createContext } from 'react';
import { AvailableLanguages } from 'lib/enums';

export interface PostTranslationContextProps {
  postId: number;
  postPathMap: Map<AvailableLanguages, string>;
  setPostPathMap: (
    newPostId: Map<AvailableLanguages, string>,
    postId: number,
  ) => void;
}

export const PostTranslationContext = createContext<
  Partial<PostTranslationContextProps>
>({
  postId: null,
  postPathMap: new Map<AvailableLanguages, string>(),
});
