import React, { ReactElement } from 'react';

export function nl2br(str: string): ReactElement {
  if (!str) {
    return null;
  }
  const parts = str.split(/\n|\n\r|\r|\n/g);
  return (
    <>
      {parts.map((part, index) => {
        if (index === parts.length - 1) {
          return <span key={index}>{part}</span>;
        }
        return (
          <span key={index}>
            {part}
            <br />
          </span>
        );
      })}
    </>
  );
}
