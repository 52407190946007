import { Action } from 'react-fetching-library';

export function postPasswordReset({
  password,
  token,
}: {
  password: string;
  token: string;
}): Action {
  return {
    method: 'POST',
    endpoint: 'auth/password/reset',
    body: {
      password,
      token,
    },
  };
}
