import React, { FC, useRef, ReactElement } from 'react';
import {
  SnackbarProvider as NotistackProvider,
  SnackbarProviderProps as NotistackProviderProps,
  WithSnackbarProps,
} from 'notistack';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

import { ColorVariantEnum } from 'lib/enums';

interface SnackbarProviderProps extends NotistackProviderProps {
  children?: ReactElement | ReactElement[];
}

export const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
  const notistackRef = useRef<WithSnackbarProps>(null);

  function onClickDismiss(key) {
    return () => {
      notistackRef.current.closeSnackbar(key);
    };
  }

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={key => (
        <Button onClick={onClickDismiss(key)} size="small">
          <WhiteCloseIcon fontSize="small" />
        </Button>
      )}
    >
      {children}
    </NotistackProvider>
  );
};

export const WhiteCloseIcon = styled(CloseIcon)`
  color: ${props => props.theme.colors.white(ColorVariantEnum.light)};
`;
