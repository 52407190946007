import React, { FC } from 'react';
import sanitize from 'sanitize-html';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import { DynamicCard } from 'components/elements';
import { PostInformation } from 'components/PostInformation';
import { useHover } from 'lib/hooks';
import { User } from 'lib/interfaces';

import { Abstract, CardLink, PostCardContent } from './PostCard.styles';

interface PostCardProps {
  author: User;
  content: string;
  createdOn: string;
  illustration?: number;
  slug: string;
  title: string;
}

export const PostCard: FC<PostCardProps> = ({
  author,
  content,
  createdOn,
  slug,
  title,
}) => {
  const [isHover, onEnter, onLeave] = useHover(false);
  const theme = useTheme();
  return (
    <CardLink to={`news/${slug}`}>
      <DynamicCard
        colored
        elevation={isHover ? 12 : 2}
        onMouseOver={onEnter}
        onMouseOut={onLeave}
      >
        <PostCardContent isXs={useMediaQuery(theme.breakpoints.down('xs'))}>
          <Typography variant="h5">{title}</Typography>
          <PostInformation
            author={author}
            createdOn={createdOn}
            isHover={isHover}
          />
          <Abstract
            className="truncate-overflow"
            dangerouslySetInnerHTML={{
              __html: sanitize(content, {
                allowedTags: ['div', 'p', 'span'],
              }),
            }}
          />
        </PostCardContent>
      </DynamicCard>
    </CardLink>
  );
};
