import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const SpaceGrid = styled(Grid)`
  padding-top: ${props => props.theme.spacing.s8};
  padding-right: ${props => props.theme.spacing.s8};
`;

export const LogoutGrid = styled(Grid)`
  width: 100%;
  margin-top: ${props => props.theme.spacing.s10};

  a,
  a:hover,
  a:visited {
    color: ${props => props.theme.colors.white()} !important;
  }
`;
