import React, { ReactElement } from 'react';

import { Divider } from 'components/Divider';

export function useFormatting(html: string): ReactElement {
  const divTagsStripped = formatQuill(html);
  return insertDivider(divTagsStripped);
}

function formatQuill(html: string) {
  return html.replace(/(div.+)( title=".+")/g, divReplacer);
}

function divReplacer(match, tag): string {
  return tag;
}

function insertDivider(raw: string): ReactElement {
  if (raw.includes('<hr />')) {
    const [left, right] = raw.split('<hr />');
    return (
      <>
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: left,
          }}
        />
        <Divider />
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: right,
          }}
        />
      </>
    );
  }
  return (
    <div
      className="ql-editor"
      dangerouslySetInnerHTML={{
        __html: raw,
      }}
    />
  );
}
